import React, { useState, useEffect, useContext } from 'react';
import pusher from '../utils/pusher'; // Import Pusher instance
import { auth } from '../utils/firebase'; // Import Firebase auth
import { onAuthStateChanged } from 'firebase/auth';
import { useParams } from 'react-router-dom'; // Import useParams
import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext
import axios from 'axios';

const Chat = () => {
  const { chatId } = useParams(); // Get chatId from URL params
  const { userId, customerId, isAdmin } = useContext(AuthContext); 
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeParticipant, setActiveParticipant] = useState(null); // Track the active participant


  const fetchAdminData = async (user, token) => {
    try {
      const adminResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/rest/admins/by-firebase-uid/${user.uid}`,
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      );
      const adminData = adminResponse.data;

      console.log("Admin data fetched:", adminData);  // Debugging log

      // Set the admin as the active participant
      setActiveParticipant({
        participantId: adminData.adminId,
        participantType: 'admin',
      });

    } catch (err) {
      console.error('Failed to fetch admin data:', err);
      setError('Failed to fetch admin data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch the token when the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
        await fetchAdminData(user, token);
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!token || !chatId) return;

    // Fetch chat history and determine active participant
    const fetchChatHistory = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/chats/${chatId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const chatData = await response.json();
        setMessages(chatData.messages || []);

        setLoading(false);
      } catch (err) {
        console.error('Failed to load chat history:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchChatHistory();
  }, [chatId, token, userId, customerId]);

  useEffect(() => {
    if (!token || !chatId) return;

    // Subscribe to the chat room
    const channel = pusher.subscribe(`chat-${chatId}`);

    // Bind to the receiveMessage event
    channel.bind('receiveMessage', (newMessage) => {
      console.log('Received message:', newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    // Clean up on component unmount
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${chatId}`);
    };
  }, [chatId, token]);

  const handleSendMessage = () => {
    if (message.trim() && token && activeParticipant) {
      const newMessage = {
        senderId: activeParticipant.participantId,
        senderType: "admin",
        text: message,
        timestamp: new Date(),
      };

      fetch(`${process.env.REACT_APP_API_URL}/rest/chats/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.globalAuthToken}`,
        },
        body: JSON.stringify({ chatId, message: newMessage }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Message sent:', data);
        setMessage('');
      })
      .catch(error => console.error('Error:', error));
    }
  };

  if (loading) {
    return <div>Loading chat history...</div>;
  }

  if (error) {
    return <div>Error loading chat history: {error}</div>;
  }


  return (
    <div>
      <h2>Chat Room {chatId}</h2>
      <div>
        {messages.map((msg, index) => (
          <div key={`${msg._id || index}-${index}`}>
            <strong>{msg.senderType}:</strong> {msg.text}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default Chat;
