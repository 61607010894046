import React, { useState, useEffect, useContext, useRef } from 'react';
import pusher from '../utils/pusher'; // Import Pusher instance
import { auth } from '../utils/firebase'; // Import Firebase auth
import { onAuthStateChanged } from 'firebase/auth';
import { useParams, Link } from 'react-router-dom'; // Import useParams and Link
import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext
import axios from 'axios';
import { FaUser, FaBuilding, FaCalendarAlt, FaPhone, FaMapMarkerAlt, FaCheck, FaTimes, FaChevronDown, FaChevronUp, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import MapSelector from './MapSelector'; // Import MapSelector component
import './Chat.css'; // Import your CSS file for styling


import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage, responsive, placeholder, AdvancedVideo } from '@cloudinary/react';
import CloudinaryUploadWidget from './CloudinaryUploadWidget';


const Chat = () => {
  const { chatId } = useParams();
  const { userId, customerId, isUser } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [deletingImageLoad, setDeletingImageLoad] = useState(true);
  const [error, setError] = useState(null);
  const [activeParticipant, setActiveParticipant] = useState(null);
  const [offer, setOffer] = useState(null);
  const [order, setOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [company, setCompany] = useState(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [initialMarker, setInitialMarker] = useState({ lat: null, lng: null }); // Initialize state unconditionally
  const messagesContainerRef = useRef(null);
  const [user, setUser] = useState(null);
  const [openedPublicId, setOpenedPublicId] = useState();
  const [scale, setScale] = useState(1); // Zoom scale
  const imageRef = useRef(null); // Reference to the image

  const [isDragging, setIsDragging] = useState(false); // To track dragging state
  const startPosition = useRef({ x: 0, y: 0 }); // To store starting position for dragging
  const startOffset = useRef({ x: 0, y: 0 }); // To store the offset from the mouse down
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Position for dragging




    const lastDistance = useRef(0); // Track the last pinch distance
    // Configuration
    const cloudName = 'dycnncntw';
    const uploadPreset = 'ml_default';
  
    // State
    const [uploadedPublicIds, setUploadedPublicIds] = useState([]); // State for uploaded files
    const [uploads, setUploads] = useState([]);

    // Cloudinary configuration
    const cld = new Cloudinary({
      cloud: {
        cloudName,
      },
    });
  
    // Upload Widget Configuration
    const uwConfig = {
      cloudName,
      uploadPreset,
      // Uncomment and modify as needed:
      // cropping: true,
      // showAdvancedOptions: true,
      // sources: ['local', 'url'],
      // multiple: false,
      // folder: 'user_images',
      // tags: ['users', 'profile'],
      // context: { alt: 'user_uploaded' },
      // clientAllowedFormats: ['images'],
      // maxImageFileSize: 2000000,
      // maxImageWidth: 2000,
      // theme: 'purple',
    };





  // Hook for authentication token
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setUser(user)
        setToken(token);
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  const markMessagesAsRead = async (activeParticipant) => {
    if (!activeParticipant?.participantType) {
      return
    }

    try {
      // Make a request to mark all messages as read for the active participant
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/chats/${chatId}/read/${activeParticipant.participantType}/${activeParticipant.participantId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );
  
      console.log("Messages marked as read successfully", response.data);
    } catch (err) {
      console.error("Error marking messages as read", err);
    }
  };

  // Hook for fetching chat history
  useEffect(() => {
    if (!token || !chatId) return;

    const fetchChatHistory = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/chats/${chatId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const chatData = await response.json();
        setMessages(chatData.messages || []);

        let activeParticipant = chatData.participants.find(participant => 
          (participant.participantType === 'user' && participant.participantId === userId) || 
          (participant.participantType === 'customer' && participant.participantId === customerId)
        );
        
        if (!activeParticipant) {
          activeParticipant = {}
          if (userId) {
            activeParticipant.participantType = "user"
            activeParticipant.participantId = userId
          } else if (customerId) {
            activeParticipant.participantType = "customer"
            activeParticipant.participantId = customerId           
          }
        }

        setActiveParticipant(activeParticipant);
        markMessagesAsRead(activeParticipant)

        const offerMessage = chatData.messages.find(msg => msg.offer && msg.offer.offerId);
        
        let tmpCustomerId = null;
        
        if (offerMessage) {
          setOffer(offerMessage.offer.offerId);
          setOrder(offerMessage.offer.orderId);
          tmpCustomerId = offerMessage.offer?.orderId?.customerId;
        } 

        if (activeParticipant?.participantType === "customer") {
          tmpCustomerId = activeParticipant.participantId;
        }

        if (customerId) {
          tmpCustomerId = customerId;
        }

        // Fetch customer and company data
        if (tmpCustomerId) {
          const customerResponse = await axios.get(`${process.env.REACT_APP_API_URL}/rest/customers/by-id/${tmpCustomerId}`, {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          });
          setCustomer(customerResponse.data);
        }

        if (offerMessage?.offer && offerMessage.offer.offerId) {
          const companyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/rest/companies/by-id/${offerMessage?.offer?.offerId?.company?.companyId}`, {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          });
          setCompany(companyResponse.data);
        }

        if (offerMessage?.offer && offerMessage.offer.orderId) {
          const orderResponse = await axios.get(`${process.env.REACT_APP_API_URL}/rest/orders/details/${offerMessage?.offer?.offerId?.orderId}`, {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          });
          setOrder(orderResponse.data);
        }

        setLoading(false);
        setMapLoaded(true); // Set map as loaded once data is fetched
      } catch (err) {
        console.error('Failed to load chat history:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchChatHistory();
  }, [chatId, token, userId, customerId, deletingImageLoad]);

  // Hook for updating initial marker based on order
  useEffect(() => {
    if (order?.addressFromTow) {
      try {
        // Parse the JSON string into an object
        const addressFromTow = JSON.parse(order.addressFromTow);
        
        const lat = parseFloat(addressFromTow.lat);
        const lng = parseFloat(addressFromTow.lng);
        
        if (!isNaN(lat) && !isNaN(lng)) {
          setInitialMarker({ lat, lng });
        }
      } catch (error) {
        console.error('Failed to parse addressFromTow:', error);
      }
    }
  }, [order]);


  // Hook for handling incoming messages
  useEffect(() => {
    if (!token || !chatId) return;

    const channel = pusher.subscribe(`chat-${chatId}`);

    channel.bind('receiveMessage', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      if (newMessage.offer && newMessage.offer.offerId) {
        setOffer(newMessage.offer.offerId);
        setOrder(newMessage.offer.orderId);
      }
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${chatId}`);
    };
  }, [chatId, token, window.globalAuthToken]);

  // Scroll messages to the bottom when new messages arrive
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages, loading, isInfoOpen]);

  const handleSendMessage = async () => {
    if (!message.trim() || !activeParticipant) return;
  
    try {
      const newMessage = {
        senderId: activeParticipant.participantId,
        senderType: activeParticipant.participantType,
        text: message,
        files: uploads, // Attach uploaded public IDs to the message
        timestamp: new Date(),
        readBy: [{ participantType: activeParticipant.participantType, participantId: activeParticipant.participantId }],
      };
  
      setUploads([]);
      setMessage('');
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/chats/sendMessage`,
        { chatId, message: newMessage },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`, // Use the refreshed token
          },
        }
      );
  
      // Axios automatically checks for response.ok, so no need for additional error checks here
      if (response.status !== 200) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  

  const handleAcceptOffer = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/rest/orders/accept-offer/${offer.orderId}`,
        { offerId: offer.offerId},
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );
      setOffer(response.data[0]);
      setOrder(response.data[1]);

    } catch (err) {
      console.error('Error accepting offer:', err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  const handleDeclineOffer = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/rest/orders/decline-offer/${offer.orderId}`,
        { 
          offerId: offer.offerId,
          isReturnOrder: false
         },
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );
      setOffer(response.data);
    } catch (err) {
      console.error('Error declining offer:', err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  const toggleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };


  const handleFullClick = (e, publicId) => {
    if (!openedPublicId) {
      setOpenedPublicId(publicId);
    } else {
      setOpenedPublicId(null);
    }
  };
  
    // Handle pinch zoom
    const calculateDistance = (touch1, touch2) => {
      return Math.sqrt(
        Math.pow(touch2.clientX - touch1.clientX, 2) +
          Math.pow(touch2.clientY - touch1.clientY, 2)
      );
    };
  


    const handleTouchMove = (e) => {
      if (e.touches.length === 2) {
        // Pinch gesture
        const newDistance = calculateDistance(e.touches[0], e.touches[1]);
        let newScale = scale * (newDistance / lastDistance.current);
        
        // Adjust the scale for more mobile responsiveness
        newScale = Math.max(0.5, Math.min(newScale, 5)); // Increase max zoom level (e.g., 5 for mobile)
    
        setScale(newScale);
        lastDistance.current = newDistance; // Update the distance for the next move
      } else if (isDragging && e.touches.length === 1) {
        // Dragging movement (one touch)
        const dx = e.touches[0].clientX - startPosition.current.x;
        const dy = e.touches[0].clientY - startPosition.current.y;
        
        // Get the element that is being dragged (the image)
        const imageElement = e.target;
        
        // Get the image's actual width and height
        const imageWidth = imageElement.naturalWidth * scale;
        const imageHeight = imageElement.naturalHeight * scale;
        
        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        
        // Calculate boundaries to prevent dragging out of the viewport
        const maxX = Math.max(0, imageWidth - containerWidth) / 100;  // Maximum X drag boundary
        const maxY = Math.max(0, imageHeight - containerHeight) / 50;  // Maximum Y drag boundary
        
        // Tentative new positions
        let newX = startOffset.current.x + dx;
        let newY = startOffset.current.y + dy;
        
        // Clamp the position to keep the image within bounds
        newX = Math.max(-maxX, Math.min(newX, maxX));  // Prevent moving the image beyond the left or right edge
        newY = Math.max(-maxY, Math.min(newY, maxY));  // Prevent moving the image beyond the top or bottom edge
        
        // Apply the new position using requestAnimationFrame for smooth movement
        requestAnimationFrame(() => {
          setPosition({ x: newX, y: newY });
        });
      }
    };
    

  const handleTouchEnd = () => {
    setIsDragging(false);
  };


  const handleMouseDown = (e) => {
    setIsDragging(true);
    startPosition.current = { x: e.clientX, y: e.clientY };
    startOffset.current = { x: position.x, y: position.y };
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleWheel = (event) => {
    if (imageRef.current && openedPublicId) {
      let newScale = scale;
  
      if (event.deltaY < 0) {
        newScale *= 1.1;
      }
      if (event.deltaY > 0) {
        newScale *= 0.9;
      }
  
      // Limit zoom level
      newScale = Math.max(1, Math.min(newScale, 3)); // Ensure 1 is minimum scale
      setScale(newScale);
  
      // Reset position if zooming out to 1x
      if (newScale === 1) {
        setPosition({ x: 0, y: 0 });
      }
    }
  };
  
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      // Pinch gesture start
      lastDistance.current = calculateDistance(e.touches[0], e.touches[1]);
    } else if (e.touches.length === 1 && scale > 1) {
      // Dragging start, only if zoomed
      setIsDragging(true);
      startPosition.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };
      startOffset.current = { ...position };
    }
  };
  

  const trackMouseMovement = (e) => {
    if (!isDragging || scale <= 1) return; // Only allow dragging when zoomed in
  
    const dx = e.clientX - startPosition.current.x;
    const dy = e.clientY - startPosition.current.y;
  
    // Get the element that is being dragged (the image)
    const imageElement = e.target;
  
    // Get the image's actual width and height
    const imageWidth = imageElement.naturalWidth * scale;
    const imageHeight = imageElement.naturalHeight * scale;
  
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
  
    // Calculate boundaries to prevent dragging out of the viewport
    const maxX = Math.max(0, imageWidth - containerWidth) /50;  // Maximum X drag boundary
    const maxY = Math.max(0, imageHeight - containerHeight) /50;  // Maximum Y drag boundary
  
    // Tentative new positions
    let newX = startOffset.current.x + dx;
    let newY = startOffset.current.y + dy;
  
    // Clamp the position to keep the image within bounds
    newX = Math.max(-maxX, Math.min(newX, maxX));  // Prevent moving the image beyond the left or right edge
    newY = Math.max(-maxY, Math.min(newY, maxY));  // Prevent moving the image beyond the top or bottom edge
  
    // Apply the new position
    requestAnimationFrame(() => {
      setPosition({ x: newX, y: newY });
    });
  };
  

  const deleteImage = async (publicId) => {
    setDeletingImageLoad(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/chats/deleteImage/${publicId}`,
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`, // Token added here
          },
        }
      );

      if (response.status === 200) {
        setUploads(prevUploads => prevUploads.filter(upload => upload.public_id !== publicId));
      } else {
        //alert('Failed to delete the image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      //alert('Error deleting image');
    } finally {
      setDeletingImageLoad(false);
    }
  };


  return (
    <div className="chat-container">

{openedPublicId && (
      <div 
      onClick={(e) => handleFullClick(e, openedPublicId)}
      className="fullscreen-overlay">
        <AdvancedImage
          ref={imageRef}
          key={openedPublicId}
          cldImg={cld.image(openedPublicId)}
          className="uploaded-image full"
          onWheel={handleWheel} // Handle zoom
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transition: 'transform 0.1s ease-out',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={trackMouseMovement}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onDragStart={handleDragStart}
        />
      </div>
    )}

      <button className="back-button" onClick={() => window.history.back()}>
        <FaArrowLeft /> Back
      </button>
      <div className={`chat-column ${isInfoOpen ? 'info-open' : ''}`}>
        <div className="messages-container" ref={messagesContainerRef}>
          {messages.map((msg, index) => (
            <div
              key={`${msg._id || index}-${index}`}
              className={`message ${msg.senderType} ${
                msg.senderType === activeParticipant?.participantType && 
                msg.senderId === activeParticipant?.participantId ? 'me' : ''
              }`}
            >
              <strong className="sender-type">
                {msg.senderType === 'user' ? 'Company' : msg.senderType === 'admin' ? 'Admin' : 'Customer'} <br />
              </strong> 
              {msg.files &&
                  msg.files.map(({ public_id, resource_type }) => (
                    public_id ? ( // Check if public_id is defined
                      <div key={public_id} className="uploaded-media-container">
                        {resource_type === 'image' && (
                          <AdvancedImage
                            cldImg={cld.image(public_id)}
                            className="uploaded-image"
                            onClick={(e) => handleFullClick(e, public_id)}
                          />
                        )}
                        {resource_type === 'video' && (
                          <AdvancedVideo
                            cldVid={cld.video(public_id)}
                            className="uploaded-image"
                            controls
                          />
                        )}

                        {msg.senderType === activeParticipant?.participantType &&
                          msg.senderId === activeParticipant?.participantId && (
                            <button
                              className="delete-button"
                              onClick={() => deleteImage(public_id)}
                            >
                              X
                            </button>
                          )}
                      </div>
                    ) : null // If public_id is undefined, render nothing for this file
                  ))}

              {msg.text}
            </div>
          ))}
        </div>


      <div className="preview-uploaded-images">
        {uploads.map(({ public_id, resource_type }) => {
          const cloudinaryResource = cld[resource_type](public_id);

          return (
            <div key={public_id} className="uploaded-media-preview">
              {resource_type === 'image' && (
                <AdvancedImage cldImg={cloudinaryResource} className="uploaded-image-preview" />
              )}
              {resource_type === 'video' && (
                <AdvancedVideo
                  cldVid={cloudinaryResource}
                  controls
                  className="uploaded-image-preview"
                />
              )}

                <button
                  className="delete-button"
                  onClick={() => deleteImage(public_id)}
                >
                  X
                </button>
            </div>
          );
        })}
      </div>


          

    <div className="message-input-container">
          <div className="message-input-textarea">
            {/* Textarea for message input */}
            <textarea
                rows="3"
                value={message}
                enterKeyHint="send"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message..."
                onKeyDown={async (e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent the default behavior (new line)
                    await handleSendMessage(); // Use await to send the message
                  }
                }}
                className="message-textarea"
              />
          </div>
          
          <div className="message-input-actions">
            {/* Cloudinary Upload Widget with Icon */}
            <CloudinaryUploadWidget
              uwConfig={uwConfig}
              setUploads={setUploads}
              setPublicIds={setUploadedPublicIds}
              className="upload-widget"
            />
            
            {/* Send button with an icon */}
            <button
              className="send-btn"
              onClick={handleSendMessage}
            >
              <FaArrowRight /> {/* Paper plane icon */}
            </button>
          </div>
        </div>

      </div>
      <button className="toggle-info" onClick={toggleInfo}>
        {isInfoOpen ? <FaChevronUp /> : <FaChevronDown />} Info
      </button>
      <div className={`offer-column ${isInfoOpen ? 'info-open' : ''}`}>
        {offer && (
          <div className="offer-details">
            <h3>Offer # {offer.offerId} Details</h3>
            <p><FaCalendarAlt /> Estimate: {new Date(offer.estimate).toLocaleString()}</p>
            <p><FaUser /> Price: ${offer.price}</p>
            <p>Message: {offer.message}</p>
            {!isUser && !offer.declined && (
              <>
                {offer.offerId !== order?.offerId && (order?.offerId == -1 || !order?.offerId) && (
                  <button className="button-accept" onClick={handleAcceptOffer}>
                    <FaCheck /> Accept Offer
                  </button>
                )}
                <button className="button-decline" onClick={handleDeclineOffer}>
                  <FaTimes /> Decline Offer
                </button>
              </>
            )}
            {isUser && !offer.declined && (
              <button className="button-decline" onClick={handleDeclineOffer}>
                <FaTimes /> Decline Offer
              </button>
            )}
            {offer.declined ? (
              <div className="status declined"><FaTimes /> <strong>Offer Declined</strong></div>
            ) : (
              <div className="status">
                {offer.offerId === order?.offerId ? (
                  <div className="status accepted"><FaCheck /> <strong>Offer Accepted</strong></div>
                ) : (
                  <div className="status not-accepted"><FaTimes /> <strong>Offer Not Accepted</strong></div>
                )}
              </div>
            )}
          </div>
        )}
        {(customer || company || order) && (
          <div className={`info-details ${isInfoOpen ? 'open' : ''}`}>
            {customer && (
              <div className="customer-info">
                <h3>Customer Info</h3>
                <p><FaUser /> Name: {customer.name}</p>
                <p><FaPhone /> Phone: {customer.phoneNumber}</p>
              </div>
            )}
            {company && (
              <div className="company-info">
                <h3>Company Info</h3>
                <p><FaBuilding /> Company Name: {company.companyName}</p>
                <p><FaBuilding /> Company Address: {company.companyAddress}</p>
                <p><FaBuilding /> Company Y-Tunnus: {company.companyYtunnus}</p>
              </div>
            )}
            {order && (
              <div className="order-info">
                <h3>Order Info</h3>

                <p><FaCalendarAlt /> When To Tow: {new Date(order.whenToTow).toLocaleString()}</p>
                <p>Additional Info: {order.additionalInfo}</p>
                <Link to={`/order/${order.orderId}`} className="button-view-order">
                  View Order Details
                </Link>
                <p><FaMapMarkerAlt /> Address From</p>
                {initialMarker.lat !== null && initialMarker.lng !== null && (
                  <MapSelector
                    setLocation={(loc) => console.log('Location:', loc)}
                    initialMarker={initialMarker}                      
                    displaySearch={false} // Hide the search bar
                  />
                )}
                <p><FaMapMarkerAlt /> Address To: {order.addressToTow}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
