import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './Menu';
import Home from './Home';
import Login from './Login';
import Success from './Success';
import Register from './Register';
import Profile from './Profile';
import AnonymousSignIn from './AnonymousSignIn';
import OrderPage from './OrderPage';
import AdminProfile from './AdminProfile';
import UserProfile from './UserProfile';
import UserJobs from './UserJobs';
import EntityManager from './EntityManager';
import Chat from './Chat';
import AdminChat from './AdminChat';
import UserMessagesList from './UserMessagesList';
import CustomerMessagesList from './CustomerMessagesList';
import AdminMessagesList from './AdminMessagesList';
import CompanyRegistrationForm from './CompanyRegistrationForm';

const AppRouter = () => {
  const entities = ['admins', 'companies', 'customers', 'jobs', 'orders', 'users', 'settings', 'chats', 'offers', 'tickets'];
  const chatId = 5;

  return (
    <Router>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/success" element={<Success />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/chat/:chatId" element={<Chat />} />
        <Route path="/admin-chat/:chatId" element={<AdminChat />} />
        <Route path="/user-messages" element={<UserMessagesList />} />
        <Route path="/order-messages" element={<CustomerMessagesList />} />
        <Route path="/admin-messages" element={<AdminMessagesList />} />
        <Route path="/user/profile" element={<UserProfile />} />
        <Route path="/user/jobs" element={<UserJobs />} />
        <Route path="/anonymous-signin" element={<AnonymousSignIn />} />
        <Route path="/order/:orderId" element={<OrderPage />} />
        <Route path="/admin/profile" element={<AdminProfile />} />
        <Route path="/register-company" element={<CompanyRegistrationForm />} />
        {entities.map(entity => (
          <Route key={entity} path={`/admin/manage/${entity}`} element={<EntityManager entity={entity} />} />
        ))}
        {/* This is the 404 route */}
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
