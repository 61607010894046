// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Profile.css'; // Import the CSS file

const Profile = () => {
  const [user, setUser] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Add navigate hook

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setUser({ ...user, token });

        try {
          // Fetch customer data
          const customerResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/rest/customers/by-firebase-uid/${user.uid}`,
            {
              headers: { Authorization: `Bearer ${window.globalAuthToken}` },
            }
          );
          const customer = customerResponse.data;
          setCustomerData(customer);
        } catch (err) {
          setError(err.message);
        }
      } else {
        setUser(null);
        setCustomerData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="profile-container">
      {user ? (
        <div>
          <h2 className="profile-header">Profile</h2>
          <div className="profile-details">
            {/*}
            <p>UID: {user.uid}</p>
            {*/}
          </div>
          {customerData ? (
            <div className="profile-section-content">
              <h3 className="profile-section-title">Customer Data</h3>
              <p>Customer Name: {customerData.name}</p>
              <p>Email: {user.email}</p>
              <p>Customer Type: {customerData.type}</p>
              <p>Phone Number: {customerData.phoneNumber}</p>
              <p>Customer ID: {customerData.customerId}</p>
              <p>Cars: {customerData.cars.join(', ')}</p>
            </div>
          ) : (
            <p className="loading-message">Loading customer data...</p>
          )}
        </div>
      ) : (
        <p>You are not logged in.</p>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Profile;
