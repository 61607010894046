import React, { useState, useEffect } from "react";
import axios from "axios";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, AdvancedVideo } from "@cloudinary/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import FleetEditModal from "./FleetEditModal";
import "./FleetManager.css"; // Import the CSS file for styling

const FleetManager = ({ setFleetNames, companyData }) => {
  const [fleet, setFleet] = useState(
    Array.isArray(companyData.fleet) ? companyData.fleet : []
  );
  const [newFleetItem, setNewFleetItem] = useState({
    name: "",
    capabilities: "",
    weight: "",
    files: [],
  });
  const [uploads, setUploads] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFleetIndex, setEditFleetIndex] = useState(null);

  const cloudName = "dycnncntw";
  const cld = new Cloudinary({ cloud: { cloudName } });

  useEffect(() => {
    const fleetNames = fleet.map((item) => item.name);
    setFleetNames(fleetNames);
  }, [fleet]);

  const handleAddFleetItem = async () => {
    const newItem = {
      ...newFleetItem,
      capabilities: newFleetItem.capabilities.split(","),
      files: uploads,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/companies/${companyData.companyId}/fleet`,
        newItem,
        { headers: { Authorization: `Bearer ${window.globalAuthToken}` } }
      );
      setFleet((prev) => [...prev, newItem]);
      setNewFleetItem({ name: "", capabilities: "", weight: "", files: [] });
      setUploads([]);
    } catch (err) {
      console.error("Error adding fleet item:", err.message);
    }
  };

  const handleEditFleetItem = (index) => {
    setEditFleetIndex(index);
    setShowEditModal(true);
  };

  const handleSaveEditFleetItem = async (updatedItem) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/rest/companies/${companyData.companyId}/fleet/${editFleetIndex}`,
        updatedItem,
        { headers: { Authorization: `Bearer ${window.globalAuthToken}` } }
      );

      const updatedFleet = [...fleet];
      updatedFleet[editFleetIndex] = updatedItem;
      setFleet(updatedFleet);
      setShowEditModal(false);
    } catch (err) {
      console.error("Error editing fleet item:", err.message);
    }
  };

  const deleteImage = async (publicId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/chats/deleteImage/${publicId}`,
        { headers: { Authorization: `Bearer ${window.globalAuthToken}` } }
      );

      setUploads((prev) => prev.filter((upload) => upload.public_id !== publicId));

      setFleet((prevFleet) =>
        prevFleet.map((item) => ({
          ...item,
          files: item.files.filter((file) => file.public_id !== publicId),
        }))
      );
    } catch (err) {
      console.error("Error deleting image:", err.message);
    }
  };

  // Remove a fleet item
  const handleRemoveFleetItem = async (fleetKey) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/companies/${companyData.companyId}/fleet/${fleetKey}`,
        { headers: { Authorization: `Bearer ${window.globalAuthToken}` } }
      );
  
      // Update the fleet state locally after successful deletion
      const updatedFleet = fleet.filter((_, index) => index !== fleetKey);
      setFleet(updatedFleet);
    } catch (err) {
      console.error('Error removing fleet item:', err.message);
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="fleet-manager-container">
      <h2 style={{textAlign: "center"}}>Fleet Management</h2>
      <ul className="fleet-list">
        {fleet.map((item, index) => (
          <li key={index} className="fleet-item">
            <div className="fleet-details">
            <strong>{item.name}</strong> - 
            {Array.isArray(item.capabilities) 
            ? item.capabilities.join(", ") 
            : item.capabilities} |{" "}
              {item.weight}
            </div>

            {item.files && Array.isArray(item.files) && item.files.length > 0 && (
                <span>
                    {/* Carousel for Images/Videos */}
                    <Slider {...sliderSettings} className="fleet-carousel">
                    {item.files &&
                        item.files.map(({ public_id, resource_type }) => (
                        <div key={public_id} className="carousel-slide">
                            {resource_type === "image" ? (
                            <AdvancedImage cldImg={cld.image(public_id)} />
                            ) : (
                            <AdvancedVideo cldVid={cld.video(public_id)} controls />
                            )}
                        </div>
                        ))}
                    </Slider>
                </span>           
            )}

            <div className="fleet-actions">
              <button onClick={() => handleEditFleetItem(index)}>Edit</button>
              <button onClick={() => handleRemoveFleetItem(index)}>Remove</button>
            </div>
          </li>
        ))}
      </ul>

      <h3>Add New Fleet Item</h3>

      <div className="preview-uploaded-images">
        {uploads.map(({ public_id, resource_type }) => {
          const cloudinaryResource = cld[resource_type](public_id);

          return (
            <div key={public_id} className="uploaded-media-preview">
              {resource_type === 'image' && (
                <AdvancedImage cldImg={cloudinaryResource} className="uploaded-image-preview" />
              )}
              {resource_type === 'video' && (
                <AdvancedVideo
                  cldVid={cloudinaryResource}
                  controls
                  className="uploaded-image-preview"
                />
              )}

                <button
                  className="delete-button"
                  onClick={() => deleteImage(public_id)}
                >
                  X
                </button>
            </div>
          );
        })}
      </div>

      <div className="add-fleet-form">
        <input
          type="text"
          placeholder="Name"
          value={newFleetItem.name}
          onChange={(e) =>
            setNewFleetItem({ ...newFleetItem, name: e.target.value })
          }
        />
        <CloudinaryUploadWidget
          uwConfig={{ cloudName, uploadPreset: "ml_default" }}
          setUploads={setUploads}
        />
        <button onClick={handleAddFleetItem}>Add</button>
      </div>

      {showEditModal && (
        <FleetEditModal
          fleetItem={fleet[editFleetIndex]}
          onSave={handleSaveEditFleetItem}
          onClose={() => setShowEditModal(false)}
          cld={cld}
          initialUploads={fleet[editFleetIndex]?.files || []}
          deleteImage={deleteImage}
        />
      )}
    </div>
  );
};

export default FleetManager;
