import React, { useState, useEffect, useContext } from 'react';
import { auth, registerListener } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './UserMessagesList.css';
import { FaChevronDown, FaChevronRight, FaClock, FaTimesCircle, FaCheckCircle, FaTrashAlt, FaEnvelope, FaEuroSign } from 'react-icons/fa';
import MapSelector from './MapSelector'; // Import MapSelector component
import ActiveJobsCompanyUser from './ActiveJobsCompanyUser';

const UserMessagesList = () => {
  const { userId } = useContext(AuthContext);
  const [customers, setCustomers] = useState({});
  const [chats, setChats] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrders, setExpandedOrders] = useState({});
  const [expandedOffers, setExpandedOffers] = useState({});
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
        setUser(user)
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!token || !userId) return;

    const fetchChats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/chats/participant/user/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const chatsData = await response.json();
        //console.log(chatsData)
        setChats(chatsData);
        setLoading(false);
      } catch (err) {
        console.error('Failed to load chats:', err);
        setError('Failed to load chats.');
        setLoading(false);
      }
    };


      // Register the listener for "Chat" events
      const newMsgListener = (payload) => {
        if (token) {
          fetchChats();  // This could be an API call to fetch the latest chat messages
        }
      };
  
      registerListener("New message ", newMsgListener);  // Register the listener, on new message
      registerListener("Offer ", newMsgListener);  // Register the listener, on offer accepted / declined

    fetchChats();
  }, [token, userId]);

  const fetchCustomers = async (customerIds) => {
    try {
      const responses = await Promise.all(customerIds.map(id =>
        axios.get(`${process.env.REACT_APP_API_URL}/rest/customers/by-id/${id}`, {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        })
      ));
      const customersData = responses.reduce((acc, response) => {
        const customer = response.data;
        acc[customer.customerId] = customer;
        return acc;
      }, {});
      setCustomers(customersData);
    } catch (err) {
      console.error('Failed to load customers:', err);
      setError('Failed to load customers.');
    }
  };

  useEffect(() => {
    if (chats.length > 0 && token) {
      const customerIds = new Set();
      chats.forEach(chat => {

        let unreadCount = chat.messages.filter(msg => {
          return msg.readBy.every(reader => {
            return !(reader.participantType == 'user' && reader.participantId == userId);
          });
        }).length;

        chat.unreadCount = unreadCount
        chat.participants.forEach(participant => {
          if (participant.participantType === 'customer') {
            customerIds.add(participant.participantId);
          }
        });
      });

      if (customerIds.size > 0) {
        fetchCustomers(Array.from(customerIds));
      } else {
        setLoading(false);
      }
    }
  }, [chats, token]);

  if (loading) {
    return <div className="loading">Loading data...</div>;
  }

  if (error) {
    return <div className="error">Error loading data: {error}</div>;
  }

// Step 1: Convert the object to a Map
const customersMap = new Map(Object.entries(customers));

// Step 2: Sort the Map by keys (customer IDs)
const sortedCustomersMap = new Map(
  [...customersMap].sort(([keyA], [keyB]) => {
    // Numeric sorting of the keys (which are customer IDs)
    return parseInt(keyB, 10) - parseInt(keyA, 10); // Sorting in descending order
  })
);

  let customerChats = Array.from(sortedCustomersMap.entries()).map(([customerId, customer]) => {
    const customerChats = chats.filter(chat =>
      chat.participants.some(participant => participant.participantType === 'customer' && participant.participantId === customer.customerId)
    );

    const groupedChats = customerChats.reduce((acc, chat) => {
      const orderId = chat.messages[0]?.offer?.orderId?.orderId || '- Order Removed';
      if (!acc[orderId]) {
        acc[orderId] = [];
        acc[orderId].allUnreadCount = 0
      }
    
      const addressFromTow = chat.messages[0]?.offer?.orderId?.addressFromTow;
    
      if (addressFromTow) {
        try {
          const parsedAddress = JSON.parse(addressFromTow);
    
          const lat = parseFloat(parsedAddress.lat);
          const lng = parseFloat(parsedAddress.lng);
    
          acc[orderId].cords = { lat: lat, lng: lng };
        } catch (error) {
          console.error("Failed to parse addressFromTow:", error);
          // Handle or log the error if needed
        }
      }

          acc[orderId].allUnreadCount = acc[orderId].allUnreadCount + chat.unreadCount

          acc[orderId].unshift(chat);
      return acc;
    }, {});
    
      // Convert the object to an array of entries and sort by orderId numerically, with "- Order Removed" last
      const sortedGroupedChats = Object.entries(groupedChats)
        .sort(([orderIdA], [orderIdB]) => {
          // Special handling for "- Order Removed"
          if (orderIdA === "- Order Removed") return 1;
          if (orderIdB === "- Order Removed") return -1;

          // Numeric sorting for orderId
          const numA = isNaN(orderIdA) ? -Infinity : parseInt(orderIdA, 10);
          const numB = isNaN(orderIdB) ? -Infinity : parseInt(orderIdB, 10);

          return numB - numA; // Sort in descending order
        });

      // Convert the sorted array back into a Map to preserve insertion order
      const finalGroupedChats = new Map();
      sortedGroupedChats.forEach(([orderId, chats]) => {
        finalGroupedChats.set(orderId, chats);
      });


      console.log(finalGroupedChats)

    return {
      customer,
      groupedChats: finalGroupedChats, // Updated to include sorted chats
    };
  });


// Now, sort the customers and their groupedChats based on the timestamp of their latest order
const sortedCustomerChats = customerChats.sort((a, b) => {
  // Function to get the latest message timestamp for a customer
  const getLatestMessageTimestamp = (customer) => {
    let latestTimestamp = 0;

    // Iterate through all grouped chats for this customer
    customer.groupedChats.forEach((chatGroup, key) => {
      // Iterate over all chats in this group (chatGroup is an array of chats)
      chatGroup.forEach(chat => {
        const latestMessageInChat = chat.messages[chat.messages.length - 1]; // Get the last message
        if (latestMessageInChat && latestMessageInChat.timestamp) {
          const currentTimestamp = new Date(latestMessageInChat.timestamp).getTime();
          latestTimestamp = Math.max(latestTimestamp, currentTimestamp); // Get the latest timestamp
        }
      });
    });

    return latestTimestamp;
  };

  // Get the latest message timestamps for both customers
  const timestampA = getLatestMessageTimestamp(a);
  const timestampB = getLatestMessageTimestamp(b);

  // Sort customers in descending order by latest message timestamp (most recent first)
  return timestampB - timestampA;
});


// Now sort each customer's groupedChats (AKA ORDERS) by their latest message timestamp, offers are sorted by id and it's partly used in this logic by getting last offers msg
sortedCustomerChats.forEach(customer => {
  // Sort groupedChats for each customer
  const sortedGroupedChats = Array.from(customer.groupedChats.entries()).sort((a, b) => {
    // Function to get the latest message timestamp from all chats in a group
    const getLatestMessageTimestamp = (chatGroup) => {
      let latestTimestamp = 0;
      // Iterate over all chats in this group (chatGroup is an array of chats)
      chatGroup.forEach(chat => {
        const latestMessage = chat.messages[chat.messages.length - 1]; // Get the last message
        if (latestMessage && latestMessage.timestamp) {
          const currentTimestamp = new Date(latestMessage.timestamp).getTime();
          latestTimestamp = Math.max(latestTimestamp, currentTimestamp); // Update the latest timestamp if current message is later
        }
      });
      return latestTimestamp;
    };

    // Get the latest message timestamps for both groups
    const timestampA = getLatestMessageTimestamp(a[1]);
    const timestampB = getLatestMessageTimestamp(b[1]);

    // Sort in descending order (most recent first)
    return timestampB - timestampA;
  });


 // After sorting by the latest message timestamp of the group, now sort each chatGroup (order) by the latest message within that group
 sortedGroupedChats.forEach(([groupId, chatGroup]) => {
  chatGroup.sort((chatA, chatB) => {
    const latestMessageA = chatA.messages[chatA.messages.length - 1]?.timestamp;
    const latestMessageB = chatB.messages[chatB.messages.length - 1]?.timestamp;

    const timestampA = latestMessageA ? new Date(latestMessageA).getTime() : 0;
    const timestampB = latestMessageB ? new Date(latestMessageB).getTime() : 0;

    // Sort chats in descending order (most recent first)
    return timestampB - timestampA;
  });
});

  // Rebuild the groupedChats as a Map (to maintain the original structure)
  customer.groupedChats = new Map(sortedGroupedChats);
});



  const handleOrderToggle = (e, orderId) => {
    const orderCard = e.target.closest('.order-group');
    orderCard.classList.toggle('active');
    setExpandedOrders(prev => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const handleOfferToggle = (offerId) => {
    setExpandedOffers(prev => ({
      ...prev,
      [offerId]: !prev[offerId],
    }));
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const handleOfferClick = (chatId) => {
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="user-messages-list">
      <ActiveJobsCompanyUser />

      <h2 className="title">Customer Orders and Chats</h2>

      <div className="customer-cards-container">
        {customerChats.map(({ customer, groupedChats }) => (
          <div key={customer.customerId} className="customer-card">
            <div className="customer-header">
              <h3>{customer.name}</h3>
            </div>
            <div className="chats-container">
              {Array.from(groupedChats).map(([orderId, chats]) => (
                
                <div key={orderId} className="order-group"
                >
                {chats.allUnreadCount > 0 && (
                  <span className="unread-count-user">{chats.allUnreadCount}</span>
                )}
                <div className="order-header-main">
                 <button className="expand-arrow-btn"  onClick={(e) => handleOrderToggle(e, orderId)}><FaChevronRight className={`expand-arrow ${expandedOrders[orderId] ? 'expanded' : ''}`} /></button>
                  <div 
                    className="order-header" 
                    onClick={() => handleOrderClick(orderId)}
                  >
                    <span className="order-icon">
                      <strong>Order # {orderId}</strong>
                    </span>
                    <span className="order-info">
                      {chats.length} {chats.length > 1 ? 'Offers' : 'Offer'} | 
                      {chats.some(chat => !chat.messages[0]?.offer?.offerId ? 
                          false : chat.messages[0]?.offer?.offerId?.declined ? 
                          false : chat.messages[0]?.offer?.offerId?.offerId === chat.messages[0]?.offer?.orderId?.offerId) ? 
                          ' Accepted' : ' Not Accepted'}
                    </span>
                  </div>
                </div>
                  <div className={`expandable-details ${expandedOrders[orderId] ? 'big-map' : ''}`}>
                    <MapSelector
                      setLocation={(loc) => console.log('Location:', loc)}
                      initialMarker={chats.cords}                      
                      displaySearch={false} // Hide the search bar
                    />
                  </div>


                  <div 
                    className={`chat-list ${expandedOrders[orderId] ? 'expanded' : 'collapsed'}`}
                  >
                    <div className="offers-list-horizontal">
                      {chats.map((chat) => (
                        <div 
                          key={chat.chatId}
                          className="offer-item-horizontal"
                          onMouseEnter={() => handleOfferToggle(chat.messages[0]?.offer?.offerId?.offerId)}
                          onMouseLeave={() => handleOfferToggle(chat.messages[0]?.offer?.offerId?.offerId)}
                          onClick={() => handleOfferClick(chat.chatId)}
                        >

                          {chat.unreadCount > 0 && (
                            <span className="unread-count-user">{chat.unreadCount}</span>
                          )}

                          <div className="offer-header-horizontal">
                            <strong>Offer # {chat.messages[0]?.offer?.offerId?.offerId}</strong>
                            {!chat.messages[0]?.offer?.offerId ? (
                              <p>Removed <FaTrashAlt className="offer-status-icon removed" /></p>
                            ) : chat.messages[0]?.offer?.offerId?.declined ? (
                              <p>Declined <FaTimesCircle className="offer-status-icon declined" /></p>
                            ) : chat.messages[0]?.offer?.offerId?.offerId == chat.messages[0]?.offer?.orderId?.offerId ? (
                              <p>Accepted <FaCheckCircle className="offer-status-icon accepted" /></p>
                            ) : chat.messages[0]?.offer?.orderId?.offerId && (
                              <p>Not Accepted <FaCheckCircle className="offer-status-icon not-accepted" /></p>
                            )}
                            <FaChevronRight className={`expand-arrow ${expandedOffers[chat.messages[0]?.offer?.offerId?.offerId] ? 'expanded' : ''}`} />
                          </div>
                          <strong><FaEnvelope /> {chat.messages.length} messages</strong>
                          <div><FaEuroSign /> {chat.messages[0]?.offer?.offerId?.price}</div>
                          {expandedOffers[chat.messages[0]?.offer?.offerId?.offerId] && (
                            <div className="offer-details-horizontal">
                              <p><FaClock /> {new Date(chat.messages[0]?.offer?.offerId?.estimate).toLocaleString()}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserMessagesList;
