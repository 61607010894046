/* global google */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import './MapSelector.css';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapSelectorWithUsers = forwardRef(({ 
  setCustomerLocation, 
  setUserLocation, 
  initialCustomerMarker, 
  initialUserMarker, 
  updateInterval = 1000, 
  customerMarkerIcon = null, 
  userMarkerIcon = null ,
  shouldDrawRoute = false, // Add this new prop
}, ref) => {
  const [customerMarker, setCustomerMarker] = useState(initialCustomerMarker);
  const [userMarker, setUserMarker] = useState(initialUserMarker);
  const [directions, setDirections] = useState(null); // State to store route directions

  const mapRef = useRef(null);

  const loaderOptions = useMemo(() => ({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDtwm7iiDO4EOvAuZ88e8Bms4oT6532Sao', // Replace with your API key
    libraries: ['places'],
  }), []);

  const { isLoaded } = useJsApiLoader(loaderOptions);

  const updateMarkers = async (userId = null) => {
    if (setUserLocation) {
      const newUserCoords = await setUserLocation(userId);
      if (newUserCoords) {
        setUserMarker(newUserCoords);
        if (mapRef.current) {
          mapRef.current.panTo(newUserCoords);
        }
        if (shouldDrawRoute) { // Only draw the route if shouldDrawRoute is true
          drawRoute(newUserCoords, customerMarker); // Update route when userMarker changes
        }
      }
    }
  };

  const drawRoute = async (origin, destination) => {
    if (origin && destination && google && google.maps) {
      const directionsService = new google.maps.DirectionsService();

      directionsService.route(
        {
          origin: new google.maps.LatLng(origin.lat, origin.lng),
          destination: new google.maps.LatLng(destination.lat, destination.lng),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result); // Save the route to state
          } else {
            console.error('Directions request failed due to', status);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      // Fit map to markers whenever customer or user marker changes
      fitMapToMarkers();
    }
  }, [customerMarker, userMarker, isLoaded]);
  
  const fitMapToMarkers = () => {
    if (!mapRef.current || !google || !google.maps) return;
  
    const bounds = new google.maps.LatLngBounds();
    let customerMarkerPosition = customerMarker ? new google.maps.LatLng(customerMarker.lat, customerMarker.lng) : null;
    let userMarkerPosition = userMarker ? new google.maps.LatLng(userMarker.lat, userMarker.lng) : null;
  
    // Add markers to the bounds
    if (customerMarkerPosition) bounds.extend(customerMarkerPosition);
    if (userMarkerPosition) bounds.extend(userMarkerPosition);
  
    // Check if the markers are in the same position
    if (customerMarkerPosition && userMarkerPosition && customerMarkerPosition.equals(userMarkerPosition)) {
      // If markers are in the same position, center the map and set a default zoom level
      mapRef.current.setCenter(customerMarkerPosition);
      mapRef.current.setZoom(12); // Adjust default zoom level as needed
    } else {
      // If markers are at different positions, fit the bounds
      mapRef.current.fitBounds(bounds);
    }
  };
  
  
  

  useImperativeHandle(ref, () => ({
    triggerLocationUpdate: (userId) => updateMarkers(userId),
  }));

  useEffect(() => {
    if (!isLoaded) return;

    setCustomerMarker(initialCustomerMarker);
    setUserMarker(initialUserMarker);

    if (initialCustomerMarker && initialUserMarker && shouldDrawRoute) {
      drawRoute(initialUserMarker, initialCustomerMarker);
    }

    const intervalId = setInterval(updateMarkers, updateInterval);
    return () => clearInterval(intervalId);
  }, [initialCustomerMarker, initialUserMarker, updateInterval, shouldDrawRoute, isLoaded]);

  return (
    <div className="map-container">
      {!isLoaded ? (
        <div>Loading...</div>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={userMarker || customerMarker} // Center map on user or customer marker
          zoom={12}
          onLoad={(map) => (mapRef.current = map)}
          className="map"
        >
          <MarkerF position={customerMarker} icon={customerMarkerIcon} />
          <MarkerF position={userMarker} icon={userMarkerIcon} />
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      )}
    </div>
  );
});

export default MapSelectorWithUsers;
