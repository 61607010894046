import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import MapSelectorWithUsers from './MapSelectorWithUsers';
import { auth, registerListener, removeListener } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ActiveOrdersCustomer = () => {
  const { customerId } = useContext(AuthContext);
  const [activeOrders, setActiveOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [expandedOrder, setExpandedOrder] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  // State to hold the customer location
  const [customerLocation, setCustomerLocation] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user)
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchActiveOrders = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/jobs/jobs-with-status-active-or-paused?customerId=${customerId}`, {
        headers: { Authorization: `Bearer ${window.globalAuthToken}` }
      });
      setActiveOrders(response.data);
      if (response.data.length > 0) {
        setExpandedOrder(response.data[0].order._id);
        // Set default customer location on fetch
        const initialLocation = {
          lat: parseFloat(JSON.parse(response.data[0].order.addressFromTow).lat),
          lng: parseFloat(JSON.parse(response.data[0].order.addressFromTow).lng),
        };
        setCustomerLocation(initialLocation);
      }
    } catch (error) {
      console.error('Error fetching active orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Register the listener for "Chat" events
    const statusListener = (payload) => {
      if (window.globalAuthToken) {
      fetchActiveOrders();  // This could be an API call to fetch the latest chat messages
      }
    };

    registerListener("Job status", statusListener);  // Register the listener, on job status change (Active, Paused etc)
    registerListener("Offer ", statusListener);  // Register the listener, on offer accepted / declined
    registerListener("New message ", statusListener);  // Register the listener, on offer accepted / declined

  }, [window.globalAuthToken]);

  useEffect(() => {
    if (window.globalAuthToken) {
      fetchActiveOrders();
    }
  }, [customerId, window.globalAuthToken]);

  const handleOfferClick = (chatId) => {
    navigate(`/chat/${chatId}`);
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const toggleOrderExpand = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/users/by-id/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.globalAuthToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.statusText}`);
      }

      return await response.json();
    } catch (err) {
      console.error(`Failed to fetch user data for ID ${userId}:`, err);
      return null;
    }
  };

  // Define the setCustomerLocation to update the state
  const handleSetCustomerLocation = (location) => {
    setCustomerLocation(location); //just comment this to use default approach aka browser
    console.log('Customer location updated:', location);
  };

  if (loading) return <div className="loading">Loading active orders...</div>;

  return (
    <div className="active-orders-container">
      <h2 className='customer-msg-title'>Your Active Orders</h2>
      {activeOrders.length === 0 ? (
        <p className="no-orders">No active orders</p>
      ) : (
        activeOrders.map(({ order, offer, chats, user, job }) => {
          const isExpanded = expandedOrder === order._id;
          const chat = chats.find((chat) => chat.messages[0].offer.offerId.offerId == offer.offerId);

          // Count unread messages
          const unreadCount = chat.messages.filter(msg => {
            return msg.readBy.every(reader => {
            return !(reader.participantType == 'customer' && reader.participantId == customerId);
          });
        }).length;

          return (
            <div key={order._id} className={`order-card green-bg ${isExpanded ? 'expanded' : ''}`}>
              <div className="order-header" onClick={() => handleOrderClick(order.orderId)}>
                <h2>Order #{order.orderId}</h2>
                <p className={`status ${order.status?.toLowerCase()}`}>Job #{job.jobId} | {job.status}</p>
              </div>

              {isExpanded && (
                <div className="order-details">
                  <div className="section offer-box" onClick={() => handleOfferClick(chat.chatId)}>
                  {unreadCount > 0 && (
                    <div className='unread-box'>
                      <span className="unread-count-customer">{unreadCount}</span>
                    </div>
                    )}
                    <h3>Offer Details</h3>
                    <p><strong>Offer ID:</strong> #{offer.offerId}</p>
                    <p><strong>Price:</strong> {offer.price}</p>
                    <p><strong>Estimate:</strong> {offer.estimate}</p>
                    <p><strong>Company Name:</strong> {offer.companyName}</p>
                    <p style={{textAlign:"center"}}><strong>Open Chat with Company</strong> <FaEnvelope /></p>
                  </div>
                  <div className="big-map">
                    <MapSelectorWithUsers
                      initialCustomerMarker={{
                        lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                        lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                      }}
                      initialUserMarker={{
                        lat: parseFloat(user.userLastPosition?.lat),
                        lng: parseFloat(user.userLastPosition?.lng),
                      }}
                      updateInterval={10000}
                      setCustomerLocation={handleSetCustomerLocation} // Use the handler to set customer location
                      setUserLocation={async () => {
                        const userData = await fetchUserData(user.userId); // Fetch user data based on ID
                        if (userData) {
                            console.log(userData)
                          const userCoords = { lat: parseFloat(userData?.lat), lng: parseFloat(userData?.lng) };
                          console.log('User location updated:', userCoords); // Handle user location updates
                          return userCoords
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ActiveOrdersCustomer;
