import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { auth } from '../utils/firebase';
import {
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    isSignInWithEmailLink
  } from 'firebase/auth';

  import { AuthContext } from '../contexts/AuthContext';


const CompanyRegistrationForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    companyAddress: '',
    companyYtunnus: '',
    userName: '',
    email: '',
    type: 'admin', // Default type
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { isAdmin, isUser, isCustomer, isGeoActive } = useContext(AuthContext);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setUser({ ...user, token });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {

    if(user) {
        formData.email = user.email
    }

  }, [user]);


  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          window.localStorage.removeItem('emailForSignIn');
          const token = await result.user.getIdToken();
          setUser({ ...result.user, token });
        })
        .catch((error) => {
          console.error('Error during email sign-in:', error);
        });
    }
  }, []);

  const handleSendSignInLink = async () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    setEmailSent(true);
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };



  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/tickets/create-company-registration-ticket`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );

      setLoading(false);
      setSuccessMessage('Company registration ticket created successfully.');
      // Optionally, clear form after successful submission
      setFormData({
        companyName: '',
        companyAddress: '',
        companyYtunnus: '',
        userName: '',
        email: '',
        type: 'admin',
      });
    } catch (err) {
      setLoading(false);
      setError('Failed to create company registration ticket.');
    }
  };


  if (!user) {
    return (
      <div>
        <button onClick={handleGoogleLogin}>Login with Google</button>
        {emailSent ? (
          <p>Please check your email for the sign-in link.</p>
        ) : (
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <button onClick={handleSendSignInLink}>Send Sign-In Link</button>
          </div>
        )}
      </div>
    );
    }

    if(isUser) {
        return (
            <p>You already in company</p>
        );
    }

  return (
    <div>
      <h2>Company Registration</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Company Address</label>
          <input
            type="text"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Company Y-tunnus</label>
          <input
            type="text"
            name="companyYtunnus"
            value={formData.companyYtunnus}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>User Name</label>
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>

      {error && <div style={{ color: 'red' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
    </div>
  );
};

export default CompanyRegistrationForm;
