import React, { useState, useEffect } from 'react';
import { auth, signInAnonymously, onAuthStateChanged } from '../utils/firebase';

const AnonymousSignIn = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        user.getIdToken().then((idToken) => {
          window.globalAuthToken = idToken
          setToken(idToken);
        });
      } else {
        setUser(null);
        setToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAnonymousSignIn = () => {
    signInAnonymously(auth)
      .then(() => {
        console.log('Signed in anonymously');
      })
      .catch((error) => {
        console.error('Error signing in anonymously:', error);
      });
  };

  return (
    <div>
      <button onClick={handleAnonymousSignIn}>Sign In Anonymously</button>
      {user && (
        <div>
          <h3>Authenticated as Anonymous User</h3>
          <p>User ID: {user.uid}</p>
          {token && (
            <div>
              <h3>Token:</h3>
              <p>{window.globalAuthToken}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnonymousSignIn;
