import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink
} from 'firebase/auth';
import { auth } from '../utils/firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import './ContactForm.css';

const ContactForm = ({ location }) => {
  const [user, setUser] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [whenToTow, setWhenToTow] = useState(null);
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setUser({ ...user, token });

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/rest/customers/by-firebase-uid/${user.uid}`,
            {
              headers: { Authorization: `Bearer ${window.globalAuthToken}` },
            }
          );
          const data = response.data;
          if (data.length === 0) {
            setCustomerData(null);
          } else {
            setCustomerData(data);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setCustomerData(null);
          } else {
            console.error('Error fetching customer data:', error);
          }
        }
      } else {
        setUser(null);
        setCustomerData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          window.localStorage.removeItem('emailForSignIn');
          const token = await result.user.getIdToken();
          setUser({ ...result.user, token });
        })
        .catch((error) => {
          console.error('Error during email sign-in:', error);
        });
    }
  }, []);

  const handleSendSignInLink = async () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    setEmailSent(true);
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };

  const onSubmit = async (data) => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    let customerId;

    if (customerData === null) {
      const customerPayload = {
        firebaseUID: user.uid,
        type: 'Individual',
        name: `${data.firstName} ${data.lastName}`,
        phoneNumber: data.phoneNumber,
        email: user.email, // Include user's email
        cars: []
      };
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/rest/customers/create', customerPayload, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        customerId = response.data.customerId;
        setCustomerData(response.data);
      } catch (error) {
        console.error('Error creating customer:', error);
        return;
      }
    } else {
      customerId = customerData.customerId;
    }

    const formData = {
      customerId,
      ...data,
      addressFromTow: location,
      addressToTow: data.addressToTow,
      whenToTow: whenToTow,
      jobs: [{ status: 'Search' }],
      additionalInfo: data.additionalInfo
    };

    try {
      await axios.post(process.env.REACT_APP_API_URL + '/rest/orders/create', formData, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      navigate('/profile');
      window.location.reload();  // Refresh the page
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  if (!user) {
    return (
      <div>
        <button onClick={handleGoogleLogin}>Login with Google</button>
        {emailSent ? (
          <p>Please check your email for the sign-in link.</p>
        ) : (
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <button onClick={handleSendSignInLink}>Send Sign-In Link</button>
          </div>
        )}
      </div>
    );
  }


  const handleImmediateTowClick = () => {
    // Set the "immediateTow" to true, but don't touch the "whenToTow" state if the user selects a custom date
      setWhenToTow(new Date()); // Set the date to the current time only if no date is already selected
  };
  

  return (
    <div className="contact-form">
      <div>Welcome, {user.email}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Tilauksen syy
          <select {...register('orderReason')} required>
            <option value="Hinaus">Hinaus</option>
            <option value="Nostotyö">Nostotyö</option>
            <option value="Apuvirta">Apuvirta</option>
          </select>
        </label>
        <label>
          Etunimi
          <input {...register('firstName')} required />
        </label>
        <label>
          Sukunimi
          <input {...register('lastName')} required />
        </label>
        <label>
          Rekisterinumero
          <input {...register('registrationNumber')} required />
        </label>
        <label>
          Puhelin
          <input {...register('phoneNumber')} required />
        </label>
        <label>
          Address To Tow
          <input {...register('addressToTow')} />
        </label>
        <label>
          When To Tow
          <div>
          <DatePicker
          withPortal
            selected={whenToTow}
            onChange={(date) => setWhenToTow(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Select a date and time"
            required
          />
          </div>
          <button
              type="button"
              onClick={() => handleImmediateTowClick(true)}
            >
              Immediately
            </button>
        </label>
        <label>
          Additional Info
          <input {...register('additionalInfo')} />
        </label>
        <button type="submit">LÄHETÄ</button>
      </form>
    </div>
  );
};

export default ContactForm;
