import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CompanyInfo = ({ company, setCompany, privileges }) => {
  const [editing, setEditing] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: company.companyName || '',
    companyAddress: company.companyAddress || '',
  });

  useEffect(() => {
    setCompanyData({
      companyName: company.companyName || '',
      companyAddress: company.companyAddress || '',
    });
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const token = await window.globalAuthToken; // Assuming token is stored globally
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/rest/companies/by-id/${company.companyId}`,
        companyData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompany(response.data.company);
      setEditing(false);
    } catch (err) {
      console.error('Error saving company data:', err.message);
    }
  };

  return (
    <div className="company-info">
      <h3>Company Information</h3>
      {privileges.includes('manage_company') && (
      <p>Company ID: {company.companyId}</p>
        )}
      {editing ? (
        <div>
          <label>
            Company Name:
            <input
              type="text"
              name="companyName"
              value={companyData.companyName}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Company Address:
            <input
              type="text"
              name="companyAddress"
              value={companyData.companyAddress}
              onChange={handleChange}
            />
          </label>
          <br />
          <button onClick={handleSave}>Save</button>
          <button onClick={() => setEditing(false)}>Cancel</button>
        </div>
      ) : (
        <div>
          <p>Company Name: {company.companyName || 'N/A'}</p>
          <p>Address: {company.companyAddress || 'N/A'}</p>
          {privileges.includes('manage_company') && (
            <button onClick={() => setEditing(true)}>Edit</button>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;
