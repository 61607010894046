import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext
import './OrderPage.css';
import MapSelectorWithUsers from './MapSelectorWithUsers';
import { FaArrowLeft } from 'react-icons/fa';

const OrderPage = () => {
  const { userId, isUser, companyId } = useContext(AuthContext); 
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState('');
  const [estimate, setEstimate] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const mapSelectorRefs = useRef({});
  const [token, setToken] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async (token) => {
      try {
          // Check if orderId is a valid integer
          if (isNaN(parseInt(orderId, 10)) || parseInt(orderId, 10) <= 0) {
            navigate('/'); // Redirect to home if orderId is not valid
            return;
          }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/orders/details/${orderId}`, {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        });
        setOrder(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching order:', err);
        setError(err.response ? err.response.data.message : err.message);
        setLoading(false);
      }
    };

    const checkAuthAndFetchOrder = () => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken();
            window.globalAuthToken = token
            setToken(token);
            await fetchOrderDetails(token);
          } catch (err) {
            console.error('Error retrieving authentication token:', err);
            setError('Failed to authenticate user.');
            setLoading(false);
          }
        } else {
          setError('User is not authenticated. Redirecting to login.');
          setLoading(false);
          navigate('/login');
        }
      });

      return () => unsubscribe();
    };

    checkAuthAndFetchOrder();
  }, [orderId, navigate]);

  const handleSubmitOffer = async (e) => {
    e.preventDefault();

    if (!price || !estimate || !message) {
      setError('Please fill in all fields');
      return;
    }

    const participants = [
      { participantType: 'user', participantId: userId, companyId: companyId },
      { participantType: 'customer', participantId: order?.customerId }
    ];

    const offerData = {
      orderId: orderId,
      customerId: order?.customerId,
      company: { companyId: companyId, userId: userId },
      estimate,
      price: parseFloat(price),
      message
    };

    const data = {
      participants,
      offerData,
      messages: [
        {
          senderId: userId,
          senderType: 'user',
          text: message
        }
      ]
    };

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/rest/chats/createChatWithOffer`, data, {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        });
        navigate(`/chat/${response.data.chatId}`); // Redirect to the new chat page
      } else {
        setError('User is not authenticated');
      }
    } catch (err) {
      console.error('Error creating chat with offer:', err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  const handleAcceptOffer = async (offerId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/rest/orders/accept-offer/${orderId}`, 
        { offerId }, {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        });
        setOrder(response.data);
      } else {
        setError('User is not authenticated');
      }
    } catch (err) {
      console.error('Error accepting offer:', err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  const handleDeclineOffer = async (offerId) => {
    try {
      const auth = getAuth();
      setUser(auth.currentUser)
      
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/rest/orders/decline-offer/${orderId}`, 
        { 
          offerId,
          isReturnOrder: true 
        }, {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        });
        setOrder(response.data);
      } else {
        setError('User is not authenticated');
      }
    } catch (err) {
      console.error('Error declining offer:', err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };


  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/users/by-id/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.globalAuthToken}`,
        },
      });

      if (!response.ok) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
        throw new Error(`Error fetching user data: ${response.statusText}`);
      }

      return await response.json();
    } catch (err) {
      console.error(`Failed to fetch user data for ID ${userId}:`, err);
      return null;
    }
  };


  if (loading) {
    return <p>Loading order details...</p>;
  }

  // Determine if the company has made any offers and whether the user has offered on this order
  const companyHasOffered = order?.offers?.some(
    (offer) => offer.company.companyId === companyId && !offer.declined
  );
  
  return (
    <div className="order-container">
      <button className="back-button" onClick={() => window.history.back()}>
        <FaArrowLeft /> Back
      </button>

      {error && <p className="error-message">{error}</p>}
      
      {order ? (
        <div>
          <h2 className="order-title">Order Details</h2>
          <div className="order-details">
            <p><strong>Order ID:</strong> {order.orderId}</p>
            <p><strong>Customer Name:</strong> {order.customerName}</p>
            <p><strong>Registration Number:</strong> {order.registrationNumber}</p>
            <p><strong>Phone Number:</strong> {order.phoneNumber}</p>
            <p><strong>When to Tow:</strong> {new Date(order.whenToTow).toLocaleString()}</p>
            <p><strong>Address From Tow:</strong> {order.addressFromTow}</p>

          <div className='big-map'>
            <MapSelectorWithUsers
                      ref={(el) => {
                        if (el) mapSelectorRefs.current[order.orderId] = el; // Associate ref with unique ID
                      }}
                      initialCustomerMarker={{
                        lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                        lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                      }}
                      initialUserMarker={{
                        lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                        lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                      }}
                      updateInterval={10000}
                      setUserLocation={async (userId2) => {
                        const idToFetch = userId2 || userId; // Use provided userId or fallback to activeUserId
                        const userData = await fetchUserData(idToFetch); // Fetch user data
                        if (userData) {
                          console.log(userData);
                          const userCoords = { lat: parseFloat(userData?.lat), lng: parseFloat(userData?.lng) };
                          console.log("User location updated:", userCoords);
                          return userCoords;
                        }
                      }}
                    />
          </div>

            <p><strong>Address To Tow:</strong> {order.addressToTow}</p>
            <p><strong>Additional Info:</strong> {order.additionalInfo}</p>
            <p><strong>Status History:</strong> {order.statusHistory ? order.statusHistory.join(', ') : 'No status history available'}</p>
          </div>

          <h3 className="jobs-title">Jobs</h3>
          {order.jobs && order.jobs.length > 0 ? (
            <ul className="jobs-list">
              {order.jobs.map((job) => (
                <li key={job._id}>Job ID: {job._id} - Status: {job.status}</li>
              ))}
            </ul>
          ) : (
            <p className="no-jobs-message">No jobs available for this order.</p>
          )}

          {/* Create Offer Form */}
          {isUser && !companyHasOffered && (
            <div className="create-offer-container">
              <h3>Create Offer and Start Chat</h3>
              <form onSubmit={handleSubmitOffer} className="offer-form">
                <div className="form-group">
                  <label htmlFor="price">Price:</label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="estimate">Estimate Date:</label>
                  <input
                    type="datetime-local"
                    id="estimate"
                    value={estimate}
                    onChange={(e) => setEstimate(e.target.value)}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message:</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    className="form-textarea"
                  />
                </div>
                <button type="submit" className="submit-button">Create Offer and Start Chat</button>
              </form>
            </div>
          )}

          {/* Display Offers */}
          <div className="offers-container">
            <h3 className="offers-title">Offers</h3>
            {order.offers.length > 0 ? (
              <ul className="offers-list">
                {order.offers.map((offer) => (
                  <>
                    {!isUser || companyId === offer.company.companyId ? (
                      <li key={offer._id} className="offer-item">
                        <div>
                          <p><strong>Company ID:</strong> {offer.company.companyId}</p>
                          <p><strong>Offer ID:</strong> #{offer.offerId}</p>
                          <p><strong>Price:</strong> {offer.price}</p>
                          <p><strong>Estimate:</strong> {new Date(offer.estimate).toLocaleString()}</p>
                          <p><strong>Message:</strong> {offer.message}</p>

                          {!isUser && !offer.declined && (
                            <>
                              {order.offerId === offer.offerId ? (
                                <>
                                  <strong>Offer Accepted</strong>
                                  <br />
                                  <button onClick={() => handleDeclineOffer(offer.offerId)} className="decline-button">
                                    Decline Offer From Company {offer.company.companyId}
                                  </button>
                                </>
                              ) : (
                                <strong>Offer Not Accepted</strong>
                              )}
                            </>
                          )}

                          {offer.declined && <strong>Offer Declined</strong>}
                          
                          {/* Accept / Decline buttons for customer */}
                          {!isUser && !offer.declined && (!order.offerId || order.offerId === -1) && (
                            <button onClick={() => handleAcceptOffer(offer.offerId)} className="accept-button">
                              Accept Offer
                            </button>
                          )}

                          {/* Decline button for company user */}
                          {isUser && !offer.declined && (
                            <>
                              {order.offerId === offer.offerId ? (
                                <strong>Offer Accepted</strong>
                              ) : (
                                <strong>Offer Not Accepted</strong>
                              )}
                              <br />
                              <button onClick={() => handleDeclineOffer(offer.offerId)} className="decline-button">
                                Decline Offer
                              </button>
                            </>
                          )}
                        </div>
                      </li>
                    ) : (
                      <li>Offer from another company</li>
                    )}
                  </>
                ))}
              </ul>
            ) : (
              <p className="no-offers-message">No offers available for this order.</p>
            )}
          </div>

          {/* Show message if the company has made an offer */}
          {companyHasOffered && (
            <p className="company-offer-message">Your company has already made an offer for this order.</p>
          )}
        </div>
      ) : (
        <p className="no-order-message">No order details available.</p>
      )}
    </div>
  );
};

export default OrderPage;
