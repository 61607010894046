import React, { useEffect } from 'react';
import axios from 'axios';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

// Global request interceptor
const RequestInterceptorToken = ({ children }) => {
  useEffect(() => {
    // Save the original fetch function
    const originalFetch = window.fetch;

    // Override fetch
    window.fetch = async (...args) => {
      try {
        const response = await originalFetch(...args);

        // If response code is 401, refresh the token and retry the request
        if (response.status === 401) {
          const user = auth.currentUser;
          if (user) {

            const token = await user.getIdToken();
            window.globalAuthToken = token; // Save the new token globally

            // Clone the request and add the new token as a Bearer
            const newRequest = { ...args[0], headers: { ...args[0].headers, Authorization: `Bearer ${token}` } };

            // Retry the request with the new token
            const retriedResponse = await originalFetch(newRequest);
            return retriedResponse;
          }
        }

        // Return the response
        return response;
      } catch (error) {
        // Handle network or other errors
        return Promise.reject(error);
      }
    };

    // Axios Interceptor to handle 401 status globally
    const axiosInterceptor = axios.interceptors.response.use(
      async (response) => {
        if (response.status === 401) {
          const user = auth.currentUser;
          if (user) {

            const token = await user.getIdToken();
            window.globalAuthToken = token; // Save the new token globally

            // Retry the request with the new token as Bearer
            response.config.headers['Authorization'] = `Bearer ${token}`;
            return axios(response.config); // Retry the request
          }
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          const user = auth.currentUser;
          if (user) {
            user.getIdToken().then((token) => {
              error.config.headers['Authorization'] = `Bearer ${token}`;
              window.globalAuthToken = token; // Save the new token globally

              return axios(error.config); // Retry the request
            });
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup: Revert the overrides when the component is unmounted
    return () => {
      window.fetch = originalFetch;
      axios.interceptors.response.eject(axiosInterceptor); // Remove Axios interceptor
    };
  }, []);

  return children;
};

export default RequestInterceptorToken;
