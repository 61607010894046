import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const AdminMessagesList = () => {
  const { isAdmin} = useContext(AuthContext); // Get userId and userType from context
  const [chats, setChats] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(1);
  const [userType, setUserType] = useState("admin");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!token || !userId || !userType) return;
    fetchChats();
  }, [token, userType]);


  const fetchChats = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/chats/participant/${userType}/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.globalAuthToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const chatsData = await response.json();

      // Adding message count to each chat
      const chatsWithMessageCount = chatsData.map(chat => ({
        ...chat,
        messageCount: chat.messages.length, // Calculate message count
      }));

      setChats(chatsWithMessageCount);
    } catch (err) {
      console.error('Failed to load chats:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChatClick = (chatId) => {
    navigate(`/admin-chat/${chatId}`);
  };

  const handleFetchChats = () => {
    if (userId && userType) {
      setLoading(true);
      setChats([]);
      setError(null);
      fetchChats();
      // Trigger the useEffect to fetch chats with the new userId and userType
    } else {
      setError('Please provide both User ID and User Type');
    }
  };

  const handleUserTypeChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setUserType(selectedOptions.join(','));
  };

  if (loading) {
    return <div>Loading chats...</div>;
  }

  if (error) {
    return <div>Error loading chats: {error}</div>;
  }

  return (
    <div>
      <h2>Your Chats</h2>
      
      {isAdmin && (
        <div>
          <h3>Admin Tools</h3>
          <input
            type="text"
            placeholder="Enter User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <select
            value={userType}
            onChange={handleUserTypeChange}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="customer">Customer</option>
          </select>
          <button onClick={handleFetchChats}>Fetch User's Chats</button>
        </div>
      )}

      <ul>
        {chats.map((chat) => (
          <li key={chat.chatId} onClick={() => handleChatClick(chat.chatId)} style={{ cursor: 'pointer' }}>
            Chat {chat.chatId} - {chat.participants.length} participants - {chat.messageCount} messages
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminMessagesList;
