// src/MapSelector.js
import React from 'react';
import { GoogleMap, MarkerF, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import './MapSelector.css'; // Import the CSS file

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 60.1699,
  lng: 24.9384,
};

const MapSelector = ({ setLocation, initialMarker, displaySearch = true }) => {
  const [marker, setMarker] = React.useState(initialMarker || center);
  const [searchAddress, setSearchAddress] = React.useState('');

  const mapRef = React.useRef(null);
  const autocompleteRef = React.useRef(null);

  // Initialize Google Maps API loader
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDtwm7iiDO4EOvAuZ88e8Bms4oT6532Sao',
    libraries: ['places'],
  });

  // Function to handle address search
  const handleSearch = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry && place.geometry.location) {
        updateMarker({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    }
  };

  // Update marker and location
  const updateMarker = (coords) => {
    setMarker(coords);
    setLocation(coords);
    if (mapRef.current) {
      mapRef.current.panTo(coords);
    }
  };

  // Handle map click event
  const onClick = (event) => {
    if (displaySearch) {
      updateMarker({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  };

  // Function to get the user's current location
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          updateMarker(coords);
        },
        (error) => {
          console.error('Error getting user location', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  React.useEffect(() => {
    setMarker(initialMarker || center);
  }, [initialMarker]);

  return (
    <div className="map-container"> {/* main css class to setup map css */}
      {!isLoaded ? (
        <div>Loading...</div> // Customize as needed
      ) : (
        <>
          {displaySearch && (
            <div className="controls">
              <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}>
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search address"
                  value={searchAddress}
                  onChange={(e) => setSearchAddress(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                />
              </Autocomplete>
              <button className="search-button" onClick={handleSearch}>Search</button>
              <button className="search-button" onClick={handleGetUserLocation}>My Location</button>
            </div>
          )}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={marker}
            zoom={10}
            onClick={onClick}
            onLoad={(map) => (mapRef.current = map)}
            className="map"
          >
            <MarkerF position={marker} />
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default MapSelector;
