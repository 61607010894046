import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './CompanyUserManagement.css';

const CompanyUserManagement = ({ companyId, privileges, fleets }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    selectedFleet: [], // Changed to array for selected fleet
    selectedPrivileges: [],
  });
  const [success, setSuccess] = useState('');
  const [editUserData, setEditUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const canManageCompany = privileges.includes('manage_company');

  // Fetch Company Users
  const fetchCompanyUsers = async () => {
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();
      window.globalAuthToken = token;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rest/users/company/${companyId}`,
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      );

      setUsers(response.data.users || []);
      setError('');
    } catch (err) {
      setError('Failed to fetch company users.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyUsers();
  }, [companyId]);

  // Add a User
  const handleAddUser = async () => {
    if (!newUser.name || !newUser.email) return setError('Name and email are required.');
  
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();
      window.globalAuthToken = token;
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/users/create`,
        {
          name: newUser.name,
          email: newUser.email,
          companyId,
          type: 'employee',
          selectedFleet: newUser.selectedFleet || [], // Pass the selectedFleet array
          selectedPrivileges: newUser.selectedPrivileges || [], // Optional privileges field
        },
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      );
  
      setSuccess('User added successfully!');
      setNewUser({ name: '', email: '', selectedFleet: [], selectedPrivileges: [] }); // Reset form
      fetchCompanyUsers(); // Refresh the user list
    } catch (err) {
      // Check if the error response contains a message
      if (err.response && err.response.data && err.response.data.message) {
        // If the error message is found, alert it
        setError(err.response.data.message);
      } else {
        // Otherwise, display a generic error message
        setError('Failed to add user.');
      }
    } finally {
      setLoading(false);
    }
  };
  

  // Edit a User
  const handleEditUser = async (user) => {
    setEditUserData(user);
    setIsModalOpen(true); // Open modal
  };

  // Save Edited User
  const handleSaveEditUser = async () => {
    if (!editUserData.name || !editUserData.email) return setError('Name and email are required.');

    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();
      window.globalAuthToken = token;

      await axios.put(
        `${process.env.REACT_APP_API_URL}/rest/users/edit/${editUserData.userId}`,
        {
          name: editUserData.name,
          email: editUserData.email,
          selectedFleet: editUserData.selectedFleet || [], // Use selectedFleet array
          selectedPrivileges: editUserData.selectedPrivileges || [], // Optional privileges field
        },
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      );

      setSuccess('User edited successfully!');
      setEditUserData(null); // Clear form
      setIsModalOpen(false); // Close modal
      fetchCompanyUsers(); // Refresh the user list
    } catch (err) {
      setError('Failed to edit user.');
    } finally {
      setLoading(false);
    }
  };

  // Remove a User
  const handleRemoveUser = async (userId) => {
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();
      window.globalAuthToken = token;

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/users/remove/${userId}`,
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      );

      setSuccess('User removed successfully!');
      fetchCompanyUsers(); // Refresh the user list
    } catch (err) {
      setError('Failed to remove user.');
    } finally {
      setLoading(false);
    }
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditUserData(null); // Reset data when modal is closed
  };

  if (!canManageCompany) {
    return;
  }

  return (
    <div className="company-user-management">
      <h3>Company User Management</h3>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}

      <div className="add-user-section">
        <input
          type="text"
          placeholder="Enter user name"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        />
        <input
          type="email"
          placeholder="Enter user email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />

        <input
          type="text"
          placeholder="Enter privileges (comma-separated)"
          value={newUser.selectedPrivileges.join(', ')}
          style={{width:'300px'}}
          onChange={(e) =>
            setNewUser({ ...newUser, selectedPrivileges: e.target.value.split(',').map((item) => item.trim()) })
          }
        />

      {fleets && (
        <div className="checkbox-group">

              {fleets.map((fleet) => (
                <label key={fleet}>
                  <input
                    type="checkbox"
                    value={fleet}
                    checked={newUser.selectedFleet.includes(fleet)}
                    onChange={(e) => {
                      const updatedSelectedFleet = e.target.checked
                        ? [...newUser.selectedFleet, fleet]
                        : newUser.selectedFleet.filter((f) => f !== fleet);
                      setNewUser({ ...newUser, selectedFleet: updatedSelectedFleet });
                    }}
                  />
                  {fleet}
                </label>
              ))}
        </div>
              )}

        <button onClick={handleAddUser} disabled={loading}>Add User</button>
      </div>

    <hr></hr>

      <button onClick={fetchCompanyUsers} disabled={loading}>
        {loading ? 'Loading...' : 'Refresh User List'}
      </button>

      <ul className="user-list">
        {users.length > 0 ? (
          users.map((user) => (
            <li key={user.userId}>
              {user.name} ({user.email})
              <button onClick={() => handleEditUser(user)} disabled={loading}>Edit</button>
              <button disabled={user?.type === 'admin' || loading}  onClick={() => handleRemoveUser(user._id)}>Remove</button>
            </li>
          ))
        ) : (
          <p>No users found for this company.</p>
        )}
      </ul>


      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Edit User</h3>
            <input
              type="text"
              placeholder="Enter user name"
              value={editUserData?.name || ''}
              onChange={(e) => setEditUserData({ ...editUserData, name: e.target.value })}
            />
            <input
              type="email"
              placeholder="Enter user email"
              value={editUserData?.email || ''}
              onChange={(e) => setEditUserData({ ...editUserData, email: e.target.value })}
            />

<div
  className="checkbox-group"
  style={{
    display: 'flex',
    flexDirection: 'column', // Keeps the container as column layout
    maxHeight: '200px',
    overflowY: 'auto',
  }}
>
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {fleets.map((fleet, index) => (
      <label key={index} style={{ marginRight: '16px' }}>
        <input
          type="checkbox"
          value={fleet}
          checked={editUserData?.selectedFleet?.includes(fleet) || false}
          onChange={(e) => {
            const selectedFleet = [...editUserData?.selectedFleet];
            if (e.target.checked) {
              selectedFleet.push(fleet);
            } else {
              const index = selectedFleet.indexOf(fleet);
              if (index > -1) {
                selectedFleet.splice(index, 1);
              }
            }
            setEditUserData({ ...editUserData, selectedFleet });
          }}
          style={{ marginRight: '8px' }}
        />
        {fleet}
      </label>
    ))}
  </div>
</div>

            <input
              type="text"
              placeholder="Enter privileges (comma-separated)"
              value={editUserData?.selectedPrivileges?.join(', ') || ''}
              disabled={editUserData?.type === 'admin'}  // Disable the field if user type is admin
              onChange={(e) =>
                setEditUserData({
                  ...editUserData,
                  selectedPrivileges: e.target.value.split(',').map((item) => item.trim()),
                })
              }
            />
            <button onClick={handleSaveEditUser} disabled={loading}>Save Changes</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyUserManagement;
