// src/components/UserProfile.js
import React, { useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css'; // Import the CSS file
import CompanyUserManagement from './CompanyUserManagement';
import FleetManager from './FleetManager';
import CompanyInfo from './CompanyInfo'; // Import the CompanyInfo component

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [fleetNames, setFleetNames] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async (companyId) => {
      try {
        const token = await auth.currentUser.getIdToken();
        window.globalAuthToken = token
        const companyResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/rest/companies/by-id/${companyId}`,
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        );
        setCompany(companyResponse.data);
      } catch (err) {
        setError('Failed to fetch company data.');
      }
    };

    const fetchUserData = async (user) => {
      try {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/rest/users/by-firebase-uid/${user.uid}`,
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        );
        const userData = userResponse.data;
        setUser(userData);

        if (userData.companyId) {
          await fetchCompanyData(userData.companyId);
        }
      } catch (err) {
        setError('Failed to fetch user data.');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    const checkAuthAndFetchUserData = () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          await fetchUserData(user);
        } else {
          setError('User is not authenticated.');
          navigate('/login');
        }
      });

      return () => unsubscribe();
    };

    checkAuthAndFetchUserData();
  }, [navigate]);

  const handleNavigation = (path) => {
    if (!user) {
      setError('You are not authorized to access this page.');
      navigate('/login');
    } else {
      navigate(path);
    }
  };

  if (loading) {
    return <p className="loading-message">Loading user data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }


  return (
    <div className="user-profile-container">
      <h2 className="user-profile-header">User Profile</h2>
      <div className="profile-section">
        {/*}
        <p>UserID: {user.userId}</p>
        {*/}
        <p>Name: {user.name}</p>
        <p>Email: {user.email || 'N/A'}</p>
        <p>Roles: {(user.selectedPrivileges || []).join(', ') || 'N/A'}</p>
      </div>

      {company && (
        <CompanyInfo
          company={company}
          setCompany={setCompany}
          privileges={user.selectedPrivileges}
        />
      )}

      <div className="manage-section">
        {company && user.selectedPrivileges.includes('manage_company') && (
        <div className="company-management-tools">
          <h3>Manage</h3>
          <FleetManager setFleetNames={(fleet)=>setFleetNames(fleet)} companyData={company} />
          <CompanyUserManagement
              companyId={company.companyId}
              privileges={user.selectedPrivileges}
              fleets={fleetNames}
            />
        </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
