import React, { useState } from "react";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { AdvancedImage, AdvancedVideo } from "@cloudinary/react";
import axios from "axios";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FleetEditModal = ({
  fleetItem,
  onSave,
  onClose,
  cld,
  initialUploads = [],
}) => {
  const [updatedItem, setUpdatedItem] = useState({ ...fleetItem });
  const [uploadsModal, setUploadsModal] = useState(initialUploads);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave({ ...updatedItem, files: [...uploadsModal] });
  };

  const deleteImage = async (publicId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/chats/deleteImage/${publicId}`,
        { headers: { Authorization: `Bearer ${window.globalAuthToken}` } }
      );

      setUploadsModal((prev) => prev.filter((upload) => upload.public_id !== publicId));

    } catch (err) {
      console.error("Error deleting image:", err.message);
    }
  };


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Edit Fleet Item</h3>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={updatedItem.name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Capabilities (comma-separated):</label>
          <input
            type="text"
            name="capabilities"
            value={updatedItem.capabilities}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Weight:</label>
          <input
            type="text"
            name="weight"
            value={updatedItem.weight}
            onChange={handleInputChange}
          />
        </div>

        {/* Existing Image Previews */}
        {/* Carousel for Images/Videos */}
        <Slider {...sliderSettings} className="fleet-carousel">
          {uploadsModal.map(({ public_id, resource_type }) => {
            const resource =
              resource_type === "image"
                ? cld.image(public_id)
                : cld.video(public_id);

            return (
              <div key={public_id} className="uploaded-media-preview-edit">
                {resource_type === "image" ? (
                  <AdvancedImage cldImg={resource}
                  className="uploaded-image"
                  />
                ) : (
                  <AdvancedVideo cldVid={resource} controls 
                  className="uploaded-image"
                  />
                )}
                <button onClick={() => deleteImage(public_id)}>Delete</button>
              </div>
            );
          })}
        </Slider>

        {/* Upload Widget */}
        <div>
          <CloudinaryUploadWidget
            uwConfig={{
              cloudName: cld.cloudinaryConfig.cloud.cloudName,
              uploadPreset: "ml_default",
            }}
            setUploads={setUploadsModal}
          />
        </div>

        {/* Actions */}
        <div className="modal-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default FleetEditModal;
