// src/Home.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MapSelector from './MapSelector';
import ContactForm from './ContactForm';
import './Home.css'; // Import the CSS file

const Home = () => {
  const [location, setLocation] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const locationPath = useLocation(); // Get the current location object

  useEffect(() => {
    // Reset showForm state when the component mounts or location changes
    setShowForm(false);
  }, [locationPath]);

  const handleContinue = () => {
    // Ensure location is set before proceeding
    if (location) {
      setShowForm(true);
    } else {
      alert('Please select a location before continuing.');
    }
  };

  return (
    <div>
      <h1>Aloita tilaus paikantamalla sijainti tai kirjoittamalla osoite</h1>
      {showForm ? (
        <ContactForm location={location} />
      ) : (
        <div className="big-map">
          <MapSelector setLocation={(loc) => setLocation(loc)} />
          <button onClick={handleContinue} disabled={!location}>
            JATKA
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
