import React, { useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserJobs.css'; // Import the CSS file

const UserJobs = () => {
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async (companyId) => {
      try {
        const token = await auth.currentUser.getIdToken();
        window.globalAuthToken = token
        const ordersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/rest/jobs/jobs-with-status-search`,
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        );
        setOrders(ordersResponse.data);
      } catch (err) {
        setError('Failed to fetch orders.');
      } finally {
        setLoading(false);
      }
    };

    const fetchUserData = async (user) => {
      try {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/rest/users/by-firebase-uid/${user.uid}`,
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        );
        const userData = userResponse.data;
        setUser(userData);

        if (userData.companyId) {
          await fetchOrders(userData.companyId);
        } else {
          setError('User is not associated with any company.');
          setLoading(false);
        }
      } catch (err) {
        setError('Failed to fetch user data.');
        navigate('/login');
      }
    };

    const checkAuthAndFetchUserData = () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          await fetchUserData(user);
        } else {
          setError('User is not authenticated.');
          navigate('/login');
        }
      });

      return () => unsubscribe();
    };

    checkAuthAndFetchUserData();
  }, [navigate]);

  const handleRowClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  if (loading) {
    return <p>Loading orders...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <div>
      <h2>Jobs</h2>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Registration Number</th>
            <th>Phone Number</th>
            <th>When to Tow</th>
            {/*}
            <th>Address From Tow</th>
            <th>Address To Tow</th>
            {*/}
            <th>Additional Info</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId} onClick={() => handleRowClick(order.orderId)}>
              <td>{order.orderId}</td>
              <td>{order.registrationNumber}</td>
              <td>{order.phoneNumber}</td>
              <td>{new Date(order.whenToTow).toLocaleString()}</td>
              {/*}
              <td>{order.addressFromTow}</td>
              <td>{order.addressToTow}</td>
              {*/}
              <td>{order.additionalInfo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserJobs;
