import React, { useState, useEffect, useContext, useRef } from 'react';
import { auth, registerListener } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './CustomerMessagesList.css';
import MapSelector from './MapSelector'; // Import MapSelector component
import { FaChevronRight, FaEnvelope, FaEuroSign, FaCheckCircle, FaTimesCircle, FaTrashAlt, FaPlay } from 'react-icons/fa';
import axios from 'axios';
import ActiveOrdersCustomer from './ActiveOrdersCustomer';
import MapSelectorWithUsers from './MapSelectorWithUsers';

const CustomerMessagesList = () => {
  const { customerId } = useContext(AuthContext);
  const [ordersWithChats, setOrdersWithChats] = useState([]);
  const [ordersWithoutChats, setOrdersWithoutChats] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [expandedChatId, setExpandedChatId] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null); // State to store active user ID for MapSelectorWithUsers
  const mapSelectorRef = useRef();
  const mapSelectorRefs = useRef({});
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  let userDataCache = {};  // In-memory cache to store user data


  const fetchUserData = async (userId) => {
    // First, check if the user data is already in the cache
    if (userDataCache[userId]) {
      console.log(`Returning cached data for user ID ${userId}`);
      return userDataCache[userId];  // Return the cached data
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/users/by-id/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.globalAuthToken}`,
        },
      });

      if (!response.ok) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
        throw new Error(`Error fetching user data: ${response.statusText}`);
      }

      const data = await response.json();

      // Store the fetched data in the cache
      userDataCache[userId] = data;
  
      return data;
      } catch (err) {
      console.error(`Failed to fetch user data for ID ${userId}:`, err);
      return null;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user)
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
      } else {
        setToken('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!token || !customerId) return;

    let companyDataCache = {};  // In-memory cache to store company data

      // Register the listener for "Chat" events
      const newOfferListener = (payload) => {
        if (token) {
          fetchOrdersAndChats();  // This could be an API call to fetch the latest chat messages
        }
      };
  
      registerListener("New offer", newOfferListener);  // Register the listener, on new offer
      registerListener("Offer ", newOfferListener);  // Register the listener, on offer accepted/ declined
      registerListener("New message ", newOfferListener);  // Register the listener, on new msg
      registerListener("Job status", newOfferListener);  // Register the listener, on job status change (Active, Paused, etc)



    const fetchCompanyData = async (companyId) => {
      if (companyDataCache[companyId]) {
        console.log(`Returning cached data for company ID ${companyId}`);
        return companyDataCache[companyId];  // Return the cached data
      }
    

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rest/companies/by-id/${companyId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching company data: ${response.statusText}`);
        }

        const data = await response.json();

        // Store the fetched data in the cache
        companyDataCache[companyId] = data;
    
        return data;      
      } catch (err) {
        console.error(`Failed to fetch company data for ID ${companyId}:`, err);
        return null;
      }
    };


    const fetchOrdersAndChats = async () => {
      try {
        const chatsResponse = await fetch(`${process.env.REACT_APP_API_URL}/rest/chats/participant/customer/${customerId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });
    
        if (!chatsResponse.ok) {
          throw new Error(`Error fetching chats: ${chatsResponse.statusText}`);
        }
    
        const chatsData = await chatsResponse.json();
    
        const ordersResponse = await fetch(`${process.env.REACT_APP_API_URL}/rest/orders/by-customer/${customerId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.globalAuthToken}`,
          },
        });
    
        if (!ordersResponse.ok) {
          throw new Error(`Error fetching orders: ${ordersResponse.statusText}`);
        }
    
        const ordersData = await ordersResponse.json();
    
        // Maps to store orders and chat data
        const ordersMap = new Map(ordersData.map(order => [order._id, { ...order, offers: [] }]));
        const chatsMap = new Map();
    
        // Extract all unique userIds and companyIds from chatsData
        const userIds = new Set();
        const companyIds = new Set();

        let allUnreadCount = {}

        chatsData.forEach(chat => {
          if (chat && chat.messages && chat.messages.length > 0) {
            const firstMessage = chat.messages.find(msg => msg.offer?.orderId);
            const offer = firstMessage?.offer;
            const orderId = offer?.orderId?._id;
    
            if (orderId) {
              allUnreadCount[orderId] = 0
              if (!chatsMap.has(orderId)) {
                chatsMap.set(orderId, {
                  chatId: chat.chatId,
                  offers: [],
                });
              }
    
              // Collect userId and companyId for each chat
              const userId = chat.participants.find(p => p.participantType === 'user')?.participantId;
              const companyId = chat.participants.find(p => p.participantType === 'user')?.companyId;
    
              if (userId) userIds.add(userId);
              if (companyId) companyIds.add(companyId);
            }
          }
        });
    
        // Now, fetch user and company data in parallel for all unique IDs
        const fetchUserDataBatch = async (userIds) => {
          const userDataPromises = Array.from(userIds).map(userId =>
            fetchUserData(userId) // Assuming fetchUserData uses the cache and only fetches when needed
          );
          return await Promise.all(userDataPromises);
        };
    
        const fetchCompanyDataBatch = async (companyIds) => {
          const companyDataPromises = Array.from(companyIds).map(companyId =>
            fetchCompanyData(companyId) // Assuming fetchCompanyData uses the cache and only fetches when needed
          );
          return await Promise.all(companyDataPromises);
        };
    
        // Fetch user and company data before the loop
        const [usersData, companiesData] = await Promise.all([
          fetchUserDataBatch(userIds),
          fetchCompanyDataBatch(companyIds),
        ]);
    
        // Create caches for users and companies
        const userCache = new Map();
        usersData.forEach(user => {
          if (user) userCache.set(user._id, user);
        });
    
        const companyCache = new Map();
        companiesData.forEach(company => {
          if (company) companyCache.set(company.companyId, company);
        });
    

        
        // Fetch offers and populate chat data using the cached user and company data
        const offersPromises = chatsData.map(async (chat) => {
          if (chat && chat.messages && chat.messages.length > 0) {
            const firstMessage = chat.messages.find(msg => msg.offer?.orderId);
            const offer = firstMessage?.offer;
            const orderId = offer?.orderId?._id;
    
            if (orderId && ordersMap.has(orderId)) {
              let order = ordersMap.get(orderId);
    
              // Retrieve the company and user data from the cache
              const companyId = chat.participants.find(p => p.participantType === 'user')?.companyId;
              const userId = chat.participants.find(p => p.participantType === 'user')?.participantId;
    
              const userData = userCache.get(userId);
              const companyData = companyCache.get(companyId);
              const companyName = companyData?.companyName || 'Unknown';
    
              // Count unread messages
              const unreadCount = chat.messages.filter(msg => {
                return msg.readBy.every(reader => {
                  return !(reader.participantType == 'customer' && reader.participantId == customerId);
                });
              }).length;


              const newOffer = {
                offerId: offer.offerId?.offerId,
                companyUserId: userId,
                chatId: chat.chatId,
                price: offer.offerId?.price,
                declined: offer.offerId?.declined,
                estimate: offer.offerId?.estimate,
                companyName: companyName,
                messages: chat.messages,
                unreadCount: unreadCount, // Add unread messages count here
                userLocation: userData ? { lat: userData.lat, lng: userData.lng } : null,
              };
    
              const chatInfo = chatsMap.get(orderId);
              if (chatInfo && !chatInfo.offers.some(existingOffer => existingOffer.offerId === newOffer.offerId)) {
                allUnreadCount[orderId] = allUnreadCount[orderId] + unreadCount
                chatInfo.offers.push(newOffer);
              }
            }
          }
        });
    
        await Promise.all(offersPromises);
    
        // Merge the fetched data into the ordersMap
        chatsMap.forEach((chatInfo, orderId) => {
          if (ordersMap.has(orderId)) {
            const order = ordersMap.get(orderId);
            order.offers = chatInfo.offers;
            order.allUnreadCount = allUnreadCount[orderId];
          }
        });
    
        // Sort orders into with and without chats
        const ordersWithChats = [];
        const ordersWithoutChats = [];
    
        ordersMap.forEach(order => {
          if (order.offers.length > 0 || chatsMap.has(order._id)) {
            ordersWithChats.push(order);
          } else {
            ordersWithoutChats.push(order);
          }
        });
    

        // Now, sort the orders by status and then by orderId
        const sortedOrdersWithChats = ordersWithChats.sort((a, b) => {
          console.log(a)
          const statusPriority = {
            "Not Started": 1,
            "Active": 3,
            "Paused": 2,
            "Other": 0
          };

          const statusA = statusPriority[a.jobs[0]?.status] || 0;
          const statusB = statusPriority[b.jobs[0]?.status] || 0;

          if (statusA === statusB) {
            // If statuses are the same, sort by orderId (descending)
            return b.orderId - a.orderId;
          }
          return statusB - statusA; // Sort by status priority
        });

        // Set the state
        setOrdersWithChats(sortedOrdersWithChats);
        setOrdersWithoutChats(ordersWithoutChats);
        setLoading(false);
      } catch (err) {
        console.error('Failed to load data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    fetchOrdersAndChats();
  }, [customerId, token]);

  if (loading) {
    return <div className="loading">Loading orders...</div>;
  }

  if (error) {
    return <div className="error">Error loading orders: {error}</div>;
  }

  const toggleExpandOrder = (orderId) => {
    if (orderId) {
      console.log(orderId)
      setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
    }
  };


  const toggleExpandOffer = (chatId, userId, offer, orderId) => {
    console.log(offer)
    setActiveUserId(userId);
    const mapSelectorRef = mapSelectorRefs.current[orderId];
    console.log("Ref for order:", orderId, mapSelectorRef);

    // Delay the location fetch until activeUserId has been updated
      if (mapSelectorRef) {
        mapSelectorRef.triggerLocationUpdate(userId); // Pass userId to the map selector
      }
  
    setExpandedChatId(expandedChatId === chatId ? null : chatId);
  };
  

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const handleOfferClick = (offerId) => {
    navigate(`/chat/${offerId}`);
  };

  const getOfferById = (offers, offerId) => {
    return offers.find(offer => offer.offerid && offer.offerid == offerId);
  };
  
  const handleOrderCardClick = (e, orderId) => {
    // Check if the clicked target is the .order-card div, but not a child element like the map
   // if (e.target.closest('.order-card') && !e.target.closest('.map-container') && !e.target.closest('.offer-item-horizontal')) {
      // Add 'active' class if clicked on the order card, not the map or other child elements
      const orderCard = e.target.closest('.order-card');
      orderCard.classList.toggle('active');
      toggleExpandOrder(orderId)
    //}
  };
  
  

  const handleDeclineOffer = async (offer, order) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/rest/orders/decline-offer/${order.orderId}`,
        { offerId: offer.offerId },
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );
    } catch (err) {
      console.error('Error declining offer:', err);
    }
  };

  var activeOffer

  const reorderOffers = (offers) => {
    return offers.slice().sort((a, b) => {
      // Get the latest message timestamp for offer A
      const latestMessageA = a.messages && a.messages.length > 0 
        ? new Date(a.messages[a.messages.length - 1].timestamp).getTime() 
        : 0;
  
      // Get the latest message timestamp for offer B
      const latestMessageB = b.messages && b.messages.length > 0 
        ? new Date(b.messages[b.messages.length - 1].timestamp).getTime() 
        : 0;
  
      // First sort by the latest message timestamp (descending order)
      if (latestMessageA !== latestMessageB) {
        return latestMessageB - latestMessageA; // Most recent first
      }
  
      // If timestamps are the same, fall back to sorting by offerId
      if (a.offerId == b.offerId) return 0;
      if (a.offerId && !b.offerId) return -1;
      if (!a.offerId && b.offerId) return 1;
  
      // Then, handle declined offers
      if (a.declined && !b.declined) return 1;
      if (!a.declined && b.declined) return -1;
  
      // Finally, sort by price (undefined values come last)
      if (a.price === undefined && b.price !== undefined) return 1;
      if (a.price !== undefined && b.price === undefined) return -1;
  
      return 0;
    });
  };
  

  return (
    <div className="customer-messages-list">

      <ActiveOrdersCustomer />


      <h2 className='customer-msg-title'>Your Orders with Offers or Chats</h2>
      <div className="order-list">
        {ordersWithChats.map((order) => (
          <div
            key={order._id}
            className={`order-card ${order.jobs[0].status == "Active" || order.jobs[0].status == "Paused" || order.jobs[0].status == "Not Started" || order.jobs[0].status == "Search" ? 'green-bg' : ''}`}
            >
              <div className="order-header-main">
              {order.jobs[0].status != "Active" && order.jobs[0].status != "Paused" && order.jobs[0].status != "Not Started" && order.jobs[0].status != "Search" &&
              <button className="expand-arrow-btn"  onClick={(e) => handleOrderCardClick(e, order.orderId)}><FaChevronRight className={`expand-arrow ${expandedOrderId === order.orderId ? 'expanded' : ''}`} /></button>
              }
              <div className="order-header"
                onClick={() => handleOrderClick(order.orderId)}
              >
                {order.allUnreadCount > 0 && (
                  <span className="unread-count-customer">{order.allUnreadCount}</span>
                )}

                <div className="order-title">
                  <span>Order #{order.orderId}</span> | <span>{order.registrationNumber}</span> | <span>{new Date(order.whenToTow).toLocaleDateString()}</span>

                </div>
                <div className="order-status">
                  {order.offerId && order.offerId === order.offerId && order.offerId != -1 && order.jobs[0].status != "Canceled" ? (
                    <span className="status-accepted">{order.jobs[0].status /* display jobs status */} </span>
                    ) : (
                    <span className="status-not-sure">{order.jobs[0].status /* display jobs status */} </span>
                  )}
                  <span className="offer-count">
                    Got offers: {order.offers.length} <FaEnvelope />
                  </span>
                </div>
              </div>
              </div>

                  <div className="expandable-details big-map-customer">
                  <MapSelectorWithUsers
                      ref={(el) => {
                        if (el) mapSelectorRefs.current[order.orderId] = el; // Associate ref with unique ID
                      }}
                      initialCustomerMarker={{
                        lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                        lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                      }}
                      initialUserMarker={{
                        lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                        lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                      }}
                      updateInterval={10000}
                      setUserLocation={async (userId) => {
                        const idToFetch = userId || activeUserId; // Use provided userId or fallback to activeUserId

                        if (!idToFetch) {
                          return {
                            lat: parseFloat(JSON.parse(order.addressFromTow).lat),
                            lng: parseFloat(JSON.parse(order.addressFromTow).lng),
                          }
                        }

                        const userData = await fetchUserData(idToFetch); // Fetch user data
                        if (userData) {
                          console.log(userData);
                          const userCoords = { lat: parseFloat(userData?.lat), lng: parseFloat(userData?.lng) };
                          console.log("User location updated:", userCoords);
                          return userCoords;
                        }
                      }}
                    />


                </div>
 
                  <div className="offers-list-horizontal">
                    {reorderOffers(order.offers).map((offer) => (
                        <div
                          key={`${offer.offerId}-${offer.chatId}`}
                          className="offer-item-horizontal"
                          /*
                          onMouseEnter={() => toggleExpandOffer(offer.chatId, offer.companyUserId, offer, order.orderId)}
                          onMouseLeave={() => toggleExpandOffer(offer.chatId, offer.companyUserId, offer, order.orderId)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOfferClick(offer.chatId);
                          }}
                            */
                          onClick={() => toggleExpandOffer(offer.chatId, offer.companyUserId, offer, order.orderId)}
                        >
                          <div className="offer-header-horizontal">
                          {offer.unreadCount > 0 && (
                              <span className="unread-count-customer">{offer.unreadCount}</span>
                          )}
                            <strong>Offer #{offer.offerId}</strong>
                            {offer.declined ? (
                              <p>Declined <FaTimesCircle className="offer-status-icon declined" /></p>
                            ) : order.offerId === offer.offerId ? (
                              <p>Accepted <FaCheckCircle className="offer-status-icon accepted" /></p>
                            ) : offer.price === undefined ? (
                              <p>Removed <FaTrashAlt className="offer-status-icon removed" /></p>
                            ) : (
                              <p>Not Accepted <FaCheckCircle className="offer-status-icon not-accepted" /></p>
                            )}
                            <FaChevronRight className={`expand-arrow ${expandedChatId === offer.chatId ? 'expanded' : ''}`} />
                          </div>
                          <strong>{offer.companyName}</strong>
                          {
                            /*
                          }
                          <div>{offer.declined ? "Declined" : order.offerId === offer.offerId ? "Accepted" : offer.price === undefined ? "Removed" : "Not Accepted"}</div>
                          {*/}
                          <div></div>
                          <button className="chat-open-btn" onClick={() =>  handleOfferClick(offer.chatId)}>Open Chat <FaChevronRight className={`expand-arrow ${expandedChatId === offer.chatId ? 'expanded' : ''}`} /></button>


                          {expandedChatId === offer.chatId && (
                            <div className="offer-details-horizontal">
                              <p><strong>Status: </strong> {offer.declined ? "Declined" : order.offerId === offer.offerId ? "Accepted" : offer.price === undefined ? "Removed" : "Not Accepted"}</p>
                              <p><FaEnvelope /> {offer.messages.length} messages</p>
                              <p><FaEuroSign /> {offer.price !== undefined ? `${offer.price}` : 'N/A'}</p>
                              <p><strong>Company:</strong> {offer.companyName}</p>
                              {offer.estimate && <p><strong>Estimate:</strong> {new Date(offer.estimate).toLocaleString()}</p>}
                            </div>
                          )}
                        </div>
                  ))}
                  </div>
          </div>
        ))}
      </div>

      <h2 className='customer-msg-title'>Your Orders without Chats or Offers</h2>
      <div className="order-list">
        {ordersWithoutChats.map((order) => (
          <div
            key={order._id}
            className={`order-card ${expandedOrderId === order._id ? 'expanded' : ''}`}
          >
          
          <div className="order-header-main">

            <button className="expand-arrow-btn"  onClick={(e) => handleOrderCardClick(e, order.orderId)}><FaChevronRight className={`expand-arrow ${expandedOrderId === order.orderId ? 'expanded' : ''}`} /></button>
            <div className="order-header"
                        onClick={() => handleOrderClick(order.orderId)}
            >
              <div className="order-title">
              <span>Order #{order.orderId}</span> | <span>{order.registrationNumber}</span> | <span>{new Date(order.whenToTow).toLocaleDateString()}</span>
              </div>
              <div className="order-status">
                <span className="status-not-accepted">No Offers or Chats</span>
              </div>
            </div>
          </div>
            <div className="expandable-details">
              <MapSelector
                    setLocation={(loc) => console.log('Location:', loc)}
                    initialMarker={{lat : parseFloat(JSON.parse(order.addressFromTow).lat), lng : parseFloat(JSON.parse(order.addressFromTow).lng)}}                      
                    displaySearch={false} // Hide the search bar
                  />
              <p><strong>To:</strong> {order.addressToTow}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerMessagesList;
