import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminTicketManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);


  const fetchTickets = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rest/tickets`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_API_KEY}`,
          },
        }
      );
      setTickets(response.data); // Assuming response.data contains the tickets array
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Failed to fetch tickets.');
    }
  };

  // Fetch tickets when component mounts
  useEffect(() => {


    fetchTickets();
  }, []);

  // Handle creating a company and user
  const handleCreateCompanyAndUser = async (ticket) => {
    const { companyName, companyAddress, companyYtunnus, userName, email } = ticket.additionalDetails;

    const requestData = {
      companyName,
      companyAddress,
      companyYtunnus,
      userName,
      email,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rest/users/register-company`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${window.globalAuthToken}`,
          },
        }
      );
      setSuccessMessage('Company and user created successfully.');
      // Optionally, update the ticket list after creating the company

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/tickets/by-tickets-id/${ticket.ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_API_KEY}`,
          },
        }
      );

      setTickets((prevTickets) =>
        prevTickets.filter((t) => t.ticketId !== ticket.ticketId)
      ); // Remove the ticket after successful creation


    } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
            setError(`Failed to create company and user: ${err.response.data.message}`);
          } else {
            setError('Failed to create company and user. Please try again later.');
          }
    }
  };

  // Handle declining a ticket
  const handleDeclineTicket = async (ticketId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/rest/tickets/by-tickets-id/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_API_KEY}`,
          },
        }
      );
      setSuccessMessage('Ticket declined successfully.');
      // Optionally, remove the ticket from the list after it’s declined
      setTickets((prevTickets) =>
        prevTickets.filter((ticket) => ticket.ticketId !== ticketId)
      );

    } catch (err) {
      setError('Failed to decline ticket.');
    }
  };

  // Handle opening the modal with ticket details
  const handleRowClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };

  return (
    <div>
      <h2>Admin Ticket Management</h2>
      
      {loading && <p>Loading tickets...</p>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

      <table>
        <thead>
          <tr>
            <th>Ticket Type</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr
              key={ticket._id}
              onClick={() => handleRowClick(ticket)} // Open modal on row click
              style={{ cursor: 'pointer' }}
            >
              <td>{ticket.type}</td>
              <td>{ticket.subject}</td>
              <td>{ticket.status}</td>
              <td>
                {ticket.type === 'company_registration' && (
                  <>
                    <button onClick={() => handleCreateCompanyAndUser(ticket)}>
                      Create Company and User
                    </button>
                    <button onClick={() => handleDeclineTicket(ticket.ticketId)}>
                      Decline
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for displaying ticket details */}
            {isModalOpen && selectedTicket && (
            <div
                style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                }}
                onClick={handleCloseModal} // Close modal when background is clicked
            >
                <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    minWidth: '300px',
                    maxWidth: '500px',
                }}
                onClick={(e) => e.stopPropagation()} // Prevent modal closing when clicked inside
                >
                <h3>Ticket Details</h3>
                <p><strong>Type:</strong> {selectedTicket.type}</p>
                <p><strong>Subject:</strong> {selectedTicket.subject}</p>
                <p><strong>Status:</strong> {selectedTicket.status}</p>
                <p><strong>Description:</strong> {selectedTicket.description}</p>
                <p><strong>Priority:</strong> {selectedTicket.priority}</p>
                <p><strong>Created At:</strong> {new Date(selectedTicket.createdAt).toLocaleString()}</p>

                {/* Render Additional Details */}
                <h4>Additional Details</h4>
                <p><strong>Company Name:</strong> {selectedTicket.additionalDetails.companyName}</p>
                <p><strong>Company Address:</strong> {selectedTicket.additionalDetails.companyAddress}</p>
                <p><strong>Company Y-tunnus:</strong> {selectedTicket.additionalDetails.companyYtunnus}</p>
                <p><strong>User Name:</strong> {selectedTicket.additionalDetails.userName}</p>
                <p><strong>Email:</strong> {selectedTicket.additionalDetails.email}</p>

                <button onClick={handleCloseModal}>Close</button>
                </div>
            </div>
            )}
    </div>
  );
};

export default AdminTicketManagement;
