import React, { useEffect, useState, useContext } from 'react';
import { auth, signInWithPopup, googleProvider, requestNotificationPermission, getFCMToken } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './AuthWidget.css';
import PWAPrompt from 'react-ios-pwa-prompt';

const AuthWidget = () => {
  const [user, setUser] = useState(null);
  const { isAdmin, isUser, isCustomer, isGeoActive } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false); // state to track hamburger menu
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [shouldShowPWAPrompt, setShouldShowPWAPrompt] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const idToken = await user.getIdToken();
        window.globalAuthToken = idToken
        setToken(idToken);
      } else {
        setUser(null);
        setToken('');
      }
    });
    
  // Delay the notification permission check to prevent issues on iOS
  const checkNotificationPermission = async () => {
    // Use a timeout to ensure the page has loaded before checking permission
    setTimeout(() => {
      if (Notification.permission === 'granted') {
        console.log('Notifications are already enabled.');
        setNotificationsEnabled(true); // Set notifications as enabled
      }
    }, 1000); // Delay to ensure the page has stabilized
  };

  checkNotificationPermission();

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let locationInterval;
    if (isGeoActive) {
      locationInterval = setInterval(async () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/rest/users/update-location`, {
                  lat: latitude,
                  lng: longitude,
                }, {
                  headers: { Authorization: `Bearer ${window.globalAuthToken}` }
                });
              } catch (error) {
                console.error('Error updating location:', error);
              }
            },
            (error) => console.error('Geolocation error:', error)
          );
        }
      }, 10000); // Polling interval: 10 seconds
    }
    return () => clearInterval(locationInterval);
  }, [isGeoActive, window.globalAuthToken]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const toggleDropdown = () => setDropdownOpen(prev => !prev);

  const handleNotificationPermission = async () => {
    const permissionGranted = await requestNotificationPermission();
    if (permissionGranted) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((registration) => {
            console.log('Service Worker registered:', registration);
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      }
      const fcmToken = await getFCMToken();
      if (fcmToken) {
        console.log('FCM Token:', fcmToken);
        setNotificationsEnabled(true);
      }
    } else {
      alert("Notification permissions are required.");
      setShouldShowPWAPrompt(true);
    }
  };

  const navigateAndCloseMenu = (path) => {
    navigate(path);
    toggleHamburgerMenu(false); // Close the menu
  };  


  const toggleHamburgerMenu = (forceState = null) => {
    setHamburgerOpen((prev) => (forceState !== null ? forceState : !prev));
  };
  

  return (
    <nav>
      <PWAPrompt isShown={shouldShowPWAPrompt} />
      <div className="nav-container">
        <button className="hamburger-menu" onClick={() => toggleHamburgerMenu()}>
          &#9776;
        </button>
        <ul className={`nav-links ${hamburgerOpen ? 'open' : ''}`}>
          <li>
            <button onClick={() => navigateAndCloseMenu('/')}>Home</button>
          </li>
          {!isUser && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/register-company')}>Register as company / influencer</button>
                </li>
          )}
          {user ? (
            <>
              {isUser && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/user/profile')}>Company User Profile</button>
                </li>
              )}
              {isUser && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/user-messages')}>Messages</button>
                </li>
              )}
              {isUser && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/user/jobs')}>Jobs</button>
                </li>
              )}
              {isCustomer && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/order-messages')}>My Orders</button>
                </li>
              )}
              {isCustomer && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/profile')}>Profile</button>
                </li>
              )}
              {isAdmin && false && (
                <li className="dropdown-container">
                  <button onClick={toggleDropdown}>Manage Entities</button>
                  {dropdownOpen && (
                    <ul className="dropdown-menu">
                      {['admins', 'companies', 'customers', 'jobs', 'orders', 'users', 'settings', 'chats', 'offers', 'tickets'].map(entity => (
                        <li key={entity}>
                          <button onClick={() => navigateAndCloseMenu(`/admin/manage/${entity}`)}>
                            {`Manage ${entity.charAt(0).toUpperCase() + entity.slice(1)}`}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )}
              {isAdmin && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/admin/profile')}>Admin Profile</button>
                </li>
              )}
              {isAdmin && (
                <li>
                  <button onClick={() => navigateAndCloseMenu('/admin-messages')}>Admin Messages</button>
                </li>
              )}
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
              {!notificationsEnabled && (
                <li>
                  <button onClick={handleNotificationPermission}>Enable Notifications</button>
                </li>
              )}
            </>
          ) : (
            <li>
              <button onClick={() => navigateAndCloseMenu('/login')}>Login</button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default AuthWidget;
