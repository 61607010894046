import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    isSignInWithEmailLink
  } from 'firebase/auth';
  import { auth } from '../utils/firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Complete sign-in if user clicked the email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          navigate('/success');
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, []);

  const handleGoogleLogin = async () => {
    try {
        const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/success');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      await signInWithPopup(auth, provider);
      navigate('/success');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSendSignInLink = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setEmailSent(true);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <button onClick={handleGoogleLogin}>Login with Google</button>
      {/*<button onClick={handleAppleLogin}>Login with Apple</button>*/}
      {emailSent ? (
        <p>Please check your email for the sign-in link.</p>
      ) : (
        <form onSubmit={handleSendSignInLink}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          <button type="submit">Send Sign-In Link</button>
          </div>
        </form>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default Login;
