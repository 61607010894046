import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth, signInAnonymously, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';  // To generate a new deviceId if not already present

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuntlG0idL0FRQyOaEv1Gd9wD7qJ5fN3E",
  authDomain: "auth.hinauspalvelut24.fi",
  projectId: "hinausapp-fefff",
  storageBucket: "hinausapp-fefff.appspot.com",
  messagingSenderId: "16899605624",
  appId: "1:16899605624:web:b0302a63a70ba08624998d",
  measurementId: "G-JY0H7XND64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Initialize Firebase Cloud Messaging
let messaging = getMessaging(app);

// Event dispatcher
const eventListeners = [];


// Function to register a listener with a pattern and a callback
const registerListener = (pattern, callback) => {
  // Add the pattern and its associated callback to the event listeners array
  eventListeners.push({ pattern, callback });
};

// Function to remove a listener by matching the callback
const removeListener = (callback) => {
  // Filter out the listener with the provided callback
  const index = eventListeners.findIndex(listener => listener.callback === callback);
  if (index !== -1) {
    eventListeners.splice(index, 1);
  }
};

// Function to dispatch event when a matching notification is received
const dispatchEvent = (body, payload) => {
  //console.log("Received body:", body);
  //console.log(eventListeners)
  // Iterate through all registered listeners and check if the body contains the pattern
  eventListeners.forEach(({ pattern, callback }) => {
    if (body.includes(pattern)) {  // Check if the body contains the pattern
      //console.log(`Pattern matched: ${pattern}`);
      // Call the callback associated with the pattern
      callback(payload);
    }
  });
};

// Request permission for notifications
const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
    return false;
  }

  try {
    if (Notification.permission === "granted") {
      console.log("Notification permission already granted.");
      return true;
    } else if (Notification.permission === "denied") {
      console.warn("Notification permission has been denied previously.");
      return false;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.warn("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Get FCM token
const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BLG2POg9vwhg4WWwQnnsmcxCs7v2Jzgw8qoA4vBOyOirh9MlEy4j8eaizMBwUXmDIgIPA5AiPnqq1urE4MCuxSQ', // Your VAPID key
    });

    if (token) {
      console.log('FCM Registration Token:', token);

      const firebaseUserToken = await auth.currentUser.getIdToken();
      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = uuidv4();  // Generate a new deviceId
        localStorage.setItem('deviceId', deviceId);
      }

      await axios.get(`${process.env.REACT_APP_API_URL}/rest/users/permissions`, {
        headers: {
          'Authorization': `Bearer ${firebaseUserToken}`,
        },
        params: {
          token: token,
          deviceId: deviceId,
        }
      });

      return token;
    } else {
      console.warn('No FCM token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('Error retrieving FCM token:', error);
  }
};

// Show the notification
const showNotification = (title, body, link = '') => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      if (link) {
        registration.showNotification(title, {
          body: body,
          icon: `${window.location.origin}/logo192.png`,
          data: { url: link },
          actions: [{ action: "open_url", title: "Read Now" }],
        });
      } else {
        registration.showNotification(title, {
          body: body,
          icon: `${window.location.origin}/logo192.png`,
        });
      }
    });
  }
};

let refreshedToken, oldToken;

// Handle token refresh
let isTokenRefreshing = false;
const handleTokenRefresh = async () => {
  if (isTokenRefreshing) return;
  isTokenRefreshing = true;

  try {
    refreshedToken = await getToken(messaging, {
      vapidKey: 'BLG2POg9vwhg4WWwQnnsmcxCs7v2Jzgw8qoA4vBOyOirh9MlEy4j8eaizMBwUXmDIgIPA5AiPnqq1urE4MCuxSQ',
    });

    if (refreshedToken && refreshedToken != oldToken) {
      //console.log('FCM Token refreshed:', refreshedToken);

      const firebaseUserToken = await auth.currentUser.getIdToken();
      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = uuidv4();  // Generate a new deviceId if not available
        localStorage.setItem('deviceId', deviceId);
      }

      console.log(refreshedToken)
      console.log(oldToken)

      await axios.get(`${process.env.REACT_APP_API_URL}/rest/users/permissions`, {
        headers: {
          'Authorization': `Bearer ${firebaseUserToken}`,
        },
        params: {
          token: refreshedToken,
          deviceId: deviceId,
        }
      });

      oldToken = refreshedToken
    }
  } catch (error) {
    console.error('Error refreshing FCM token:', error);
  } finally {
    isTokenRefreshing = false;
  }
};

// Foreground Message Listener
onMessage(messaging, async (payload) => {
  console.log('Message received in foreground:', payload);

  if (payload.notification) {
    const { title, body } = payload.notification;
    const link = payload.fcmOptions?.link;

    // Show notification in the foreground
    showNotification(title, body, link);

    // Now we search for matching strings inside the body
    dispatchEvent(title, payload);  // Dispatch the event if the body contains a known pattern

    // Handle token refresh if needed
  }

  handleTokenRefresh();
});

setInterval(handleTokenRefresh, 10000); // Refresh every minute (10,000 ms) , this needed because otherwise after x time on ios push notifs are not working :(

/*
document.addEventListener('visibilitychange', async () => {
  console.log('Refresh FMC')
  await handleTokenRefresh();
});
*/

export {
  auth,
  googleProvider,
  messaging,
  signInAnonymously,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  requestNotificationPermission,
  getFCMToken,
  registerListener,   // Export the registerListener function
  removeListener      // Export the removeListener function
};
