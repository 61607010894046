// src/components/Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import AuthWidget from './AuthWidget';

const Menu = () => {
  return (
    <div className="menu">
      <AuthWidget />
    </div>
  );
};

export default Menu;
