import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [userType, setUserType] = useState([]); // Initialize userType as an empty array
  const [companyId, setCompanyId] = useState(null);
  const [isUser, setIsUser] = useState(null);
  const [isCustomer, setIsCustomer] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [hasActiveJobs, setHasActiveJobs] = useState(null);
  const [isGeoActive, setIsGeoActive] = useState(null);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token;
        
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/users/permissions`, {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
            params: { email: user.email }, // Add user.email as a query parameter
          });

          setUserId(response.data.userId);
          setCompanyId(response.data.companyId);
          setCustomerId(response.data.customerId);
          setIsUser(response.data.isUser);
          setIsCustomer(response.data.isCustomer);
          setIsAdmin(response.data.isAdmin);
          setHasActiveJobs(response.data.hasActiveJobs);
          setIsGeoActive(response.data.isGeoActive);
          setSelectedPrivileges(response.data.selectedPrivileges)

          // Build the userType array based on isUser and isCustomer flags
          const userTypeArray = [];
          if (response.data.isUser) userTypeArray.push('user');
          if (response.data.isCustomer) userTypeArray.push('customer');
          setUserType(userTypeArray);

        } catch (err) {
          console.error('Error fetching user permissions:', err);
          setUserId(null);
          setUserType([]); // Clear userType array on error
          setCompanyId(null);
          setCustomerId(null);
          setIsUser(null);
          setIsCustomer(null);
          setIsAdmin(null);
          setHasActiveJobs(null);
          setIsGeoActive(null);
          setSelectedPrivileges(null)
        }
      } else {
        setUserId(null);
        setUserType([]); // Clear userType array on no user
        setCompanyId(null);
        setCustomerId(null);
        setIsUser(null);
        setIsCustomer(null);
        setIsAdmin(null);
        setHasActiveJobs(null);
        setIsGeoActive(null);
        setSelectedPrivileges(null)
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, userId, companyId, customerId, isUser, isCustomer, isAdmin, hasActiveJobs, isGeoActive, selectedPrivileges }}>
      {children}
    </AuthContext.Provider>
  );
};
