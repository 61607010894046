import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ENTITY_ID_MAP = {
  admins: 'adminId',
  companies: 'companyId',
  customers: 'customerId',
  jobs: 'jobId',
  orders: 'orderId',
  users: 'userId',
  settings: 'id',
  chats: 'chatId',
  offers: 'offerId',
  tickets: 'ticketId',
};

const ENTITY_NAME_FIELD_MAP = {
  admins: 'name',
  companies: 'companyName',
  customers: 'name',
  jobs: 'orderId',
  orders: 'customerId',
  users: 'name',
  settings: 'key',
  chats: 'customerId',
  offers: 'offerId',
  tickets: 'ticketId',
};

// Dynamically generate EXCLUDED_KEYS based on ENTITY_ID_MAP and include _id and id

const EntityManager = ({ entity }) => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [error, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [nameField, setNameField] = useState(ENTITY_NAME_FIELD_MAP[entity]);
  const [availableFields, setAvailableFields] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newItemData, setNewItemData] = useState('');
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_ADMIN_API_KEY;

  const idField = ENTITY_ID_MAP[entity];
  const EXCLUDED_KEYS = ['_id', 'id', ENTITY_ID_MAP[entity]];

  useEffect(() => {
    const checkAdmin = async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          const response = await axios.get(
            `${apiUrl}/rest/admins/by-firebase-uid/${user.uid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (response.data) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (err) {
          console.error('Error fetching admin data:', err);
          setIsAdmin(false);
        } finally {
          setLoading(false);
        }
      } else {
        setIsAdmin(false);
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, checkAdmin);

    return () => unsubscribe();
  }, [apiUrl]);

  useEffect(() => {
    if (loading) return;

    if (isAdmin === false) {
      navigate('/');
    } else if (isAdmin === true) {
      fetchItems();
      fetchSampleData();
    }
  }, [isAdmin, loading, navigate]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${apiUrl}/rest/${entity}`, {
        headers: { Authorization: `Bearer ${apiKey}` }
      });
      setItems(response.data);
      if (response.data.length > 0) {
        setAvailableFields(Object.keys(response.data[response.data.length - 1]));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchSampleData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/rest/${entity}`, {
        headers: { Authorization: `Bearer ${apiKey}` }
      });
      if (response.data.length > 0) {
        const sampleItem = response.data[response.data.length - 1];
        const template = generateSampleTemplate(sampleItem);
        setNewItemData(JSON.stringify(template, null, 2));
      } else {
        setNewItemData('{}');
      }
    } catch (err) {
      console.error('Error fetching sample data:', err);
      setNewItemData('{}');
    }
  };

  const generateSampleTemplate = (item) => {
    const template = {};
    for (const key in item) {
      if (item.hasOwnProperty(key) && !EXCLUDED_KEYS.includes(key)) {
        const value = item[key];
        template[key] = value;
        continue;
        if (typeof value === 'string') {
          template[key] = 'sample text'; // Sample text for string values
        } else if (typeof value === 'number') {
          template[key] = 123; // Sample number for number values
        } else if (typeof value === 'boolean') {
          template[key] = true; // Sample boolean for boolean values
        } else if (Array.isArray(value)) {
          template[key] = []; // Empty array for array values
        } else if (typeof value === 'object' && value !== null) {
          template[key] = generateSampleTemplate(value); // Recursive for nested objects
        } else {
          template[key] = null; // Default for unknown types
        }
      }
    }
    return template;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!isAdmin) return;
    try {
      const parsedItem = JSON.parse(currentItem.data);
      await axios.post(`${apiUrl}/rest/${entity}`, parsedItem, {
        headers: { Authorization: `Bearer ${apiKey}` }
      });
      setCurrentItem(null);
      fetchItems();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = (item) => {
    if (currentItem && currentItem.id === item[idField]) {
      setCurrentItem(null);
    } else {
      setCurrentItem({
        id: item[idField],
        data: JSON.stringify(item, null, 2)
      });
    }
  };

  const handleDelete = async (id) => {
    if (!isAdmin) return;
    const confirmed = window.confirm("Are you sure you want to delete this item?");
    if (!confirmed) return;
    try {
      await axios.delete(`${apiUrl}/rest/${entity}/by-${entity}-id/${id}`, {
        headers: { Authorization: `Bearer ${apiKey}` }
      });
      fetchItems();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChange = (e) => {
    setCurrentItem({
      ...currentItem,
      data: e.target.value
    });
  };

  const handleNewItemChange = (e) => {
    setNewItemData(e.target.value);
  };

  const createNewItem = async () => {
    if (!isAdmin) return;
    try {
      const parsedItem = JSON.parse(newItemData);
      await axios.post(`${apiUrl}/rest/${entity}`, parsedItem, {
        headers: { Authorization: `Bearer ${apiKey}` }
      });
      setNewItemData('');
      setShowCreateForm(false);
      fetchItems();
    } catch (err) {
      setError(err.message);
    }
  };

  const sortItems = (items) => {
    return [...items].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleNameFieldChange = (e) => {
    setNameField(e.target.value);
  };

  const renderItemValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <div key={key}>
          <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
          <ul style={{ paddingLeft: '20px' }}>
            {Object.keys(value).map(subKey => (
              <li key={subKey}>
                <strong>{subKey.charAt(0).toUpperCase() + subKey.slice(1)}:</strong> {JSON.stringify(value[subKey])}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div key={key}>
        <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {JSON.stringify(value)}
      </div>
    );
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (isAdmin === false) {
    return <p>You are not authorized to manage this entity.</p>;
  }

  const sortedItems = sortItems(items);

  return (
    <div style={{ position: 'relative' }}>
      <h2 style={{ textAlign: 'center' }}>Manage {entity.charAt(0).toUpperCase() + entity.slice(1)}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <p><strong>Item Count:</strong> {sortedItems.length}</p> {/* Display item count */}
      <div>
        <label htmlFor="nameField">Select Key for Second Column: </label>
        <select id="nameField" value={nameField} onChange={handleNameFieldChange}>
          {availableFields.map(field => (
            <option key={field} value={field}>
              {field}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button onClick={() => setShowCreateForm(prev => !prev)}>
          {showCreateForm ? 'Cancel' : 'Create New Item'}
        </button>
        {showCreateForm && (
          <div>
            <textarea
              name="newItem"
              value={newItemData}
              onChange={handleNewItemChange}
              placeholder="Enter new item data as JSON"
              rows={10}
              cols={50}
              required
            />
            <button onClick={createNewItem}>Submit</button>
          </div>
        )}
      </div>
      <div style={{ overflowX: 'auto', marginTop: '20px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px', cursor: 'pointer' }} onClick={() => handleSort(idField)}>
                ID {sortField === idField && (sortDirection === 'asc' ? '🔼' : '🔽')}
              </th>
              <th style={{ border: '1px solid black', padding: '8px', cursor: 'pointer' }} onClick={() => handleSort(nameField)}>
                {nameField.charAt(0).toUpperCase() + nameField.slice(1)} {sortField === nameField && (sortDirection === 'asc' ? '🔼' : '🔽')}
              </th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Details</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedItems.map(item => (
              <tr key={item[idField]}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{item[idField]}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{item[nameField] || 'N/A'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  {currentItem && currentItem.id === item[idField] ? (
                    <form onSubmit={handleSave}>
                      <textarea
                        name="item"
                        value={currentItem.data}
                        onChange={handleChange}
                        placeholder="Edit item as JSON"
                        rows={10}
                        cols={30}
                        required
                      />
                      <button type="submit">Update</button>
                    </form>
                  ) : (
                    Object.keys(item)
                      .filter(key => !EXCLUDED_KEYS.includes(key) && key !== idField && key !== nameField)
                      .map(key => renderItemValue(key, item[key]))
                  )}
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <button onClick={() => handleEdit(item)}>Edit</button>
                  <button onClick={() => handleDelete(item[idField])}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EntityManager;
