import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnonymousSignIn from './components/AnonymousSignIn';
import AppRouter from './components/AppRouter'; // Assuming you have a Home component
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext';
import RequestInterceptorToken from './components/RequestInterceptorToken'; // Import the interceptor

const App = () => {
  return (
    <AuthProvider>
      <RequestInterceptorToken>
        <AppRouter />
      </RequestInterceptorToken>
    </AuthProvider>
  );
};

export default App;
