// src/utils/pusher.js

import Pusher from 'pusher-js';

// Replace with your Pusher credentials
const pusher = new Pusher('2c39fbb6b23407333f0f', {
  cluster: 'eu',
  forceTLS: true,
});

export default pusher;
