import React, { useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import AdminTicketManagement from './AdminTicketManagement';

const AdminProfile = () => {
  const [admin, setAdmin] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchAdminData = async (user) => {
      try {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        const adminResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/rest/admins/by-firebase-uid/${user.uid}`,
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        );
        const adminData = adminResponse.data;
        setAdmin(adminData);
      } catch (err) {
        //setError('Failed to fetch admin data.');
        navigate('/'); // Redirect to an unauthorized page or error page
      } finally {
        setLoading(false); // End loading
      }
    };

    const checkAuthAndFetchAdminData = () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          await fetchAdminData(user);
        } else {
          setError('User is not authenticated.');
          setLoading(false);
        }
      });

      return () => unsubscribe();
    };

    checkAuthAndFetchAdminData();
  }, []);

  useEffect(() => {
    if (error.includes('User is not authenticated')) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [error, navigate]);

  // Handle redirection based on admin roles
  const handleNavigation = (path) => {
    if (!admin) {
      setError('You are not authorized to manage this entity.');
      navigate('/'); // Redirect to an unauthorized page or error page
    } else {
      navigate(path);
    }
  };

  if (loading) {
    return <p>Loading admin data...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  const manageEntities = [
    { name: 'Admins', path: '/admin/manage/admins' },
    { name: 'Companies', path: '/admin/manage/companies' },
    { name: 'Customers', path: '/admin/manage/customers' },
    { name: 'Jobs', path: '/admin/manage/jobs' },
    { name: 'Orders', path: '/admin/manage/orders' },
    { name: 'Users', path: '/admin/manage/users' },
    { name: 'Chats', path: '/admin/manage/chats' },
    { name: 'Offers', path: '/admin/manage/offers' },
    { name: 'Tickets', path: '/admin/manage/tickets' },
    { name: 'Global Settings', path: '/admin/manage/settings' },
  ];

  return (
    <div>
      <h2>Admin Profile</h2>
      <p>Name: {admin.name}</p>
      <p>Roles: {admin.roles.join(', ')}</p>

      <AdminTicketManagement />

      <h3>Manage Entities</h3>
      <ul>
        {manageEntities.map((entity) => (
          <li key={entity.name}>
            <button onClick={() => handleNavigation(entity.path)}>
              {entity.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminProfile;
