import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { auth, registerListener } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaPause, FaPlay, FaTimesCircle, FaCheckCircle, FaChevronRight, FaChevronDown, FaEnvelope } from 'react-icons/fa';
import MapSelector from './MapSelector';
import './ActiveJobsCompanyUser.css';

const ActiveJobsCompanyUser = () => {
  const { companyId, userId, selectedPrivileges } = useContext(AuthContext);
  const [activeJobs, setActiveJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [expandedJob, setExpandedJob] = useState(null); // Track expanded job
  const [expandedCompanyJob, setExpandedCompanyJob] = useState(null); // Track expanded company job
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [companyUsers, setCompanyUsers] = useState([]);
  const [isAssigningJob, setIsAssigningJob] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        window.globalAuthToken = token
        setToken(token);
        setUser(user)
      } else {
        setToken('');
      }
    });
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    // Fetch company users if user has 'manage_jobs' privilege
    //if (selectedPrivileges.includes('manage_jobs')) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/rest/users/company/${companyId}`, {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        })
        .then((response) => {
          setCompanyUsers(response.data.users);
        })
        .catch((error) => {
          console.error('Error fetching company users:', error);
        });
    //}
  }, [companyId, selectedPrivileges]);

  const handleAssignJobToMe = (offerId) => {
    // Make request to assign job to current user
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/rest/offers/assign/${offerId}`,
        { newUserId : userId },
        {
          headers: { Authorization: `Bearer ${window.globalAuthToken}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          console.log('Job assigned to me');
        }
      })
      .catch((error) => {
        console.error('Error assigning job to me:', error);
      });
  };

  const handleAssignJobToUser = (offerId) => {
    // Make request to assign job to selected user
    if (selectedUserId) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/rest/offers/assign/${offerId}`,
          { newUserId: selectedUserId },
          {
            headers: { Authorization: `Bearer ${window.globalAuthToken}` },
          }
        )
        .then((response) => {
          if (response.data.success) {
            console.log('Job assigned to selected user');
          }
          fetchActiveJobs()
        })
        .catch((error) => {
          console.error('Error assigning job to selected user:', error);
        });
    }
  };


  const fetchActiveJobs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/jobs/jobs-with-status-active-or-paused?companyId=${companyId}`, {
        headers: { Authorization: `Bearer ${window.globalAuthToken}` }
      });
      setActiveJobs(response.data);
    } catch (error) {
      console.error('Error fetching active jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    // Register the listener for "Chat" events
    const newActiveOrderListener = (payload) => {
      if (window.globalAuthToken) {
        fetchActiveJobs();  // This could be an API call to fetch the latest chat messages
        }
      };
      
    registerListener("Offer ", newActiveOrderListener);  // Register the listener, on offer accepted / declined
    registerListener("New message ", newActiveOrderListener);  // Register the listener, on new msg

    if (window.globalAuthToken) {
      fetchActiveJobs();
    }
  }, [companyId, window.globalAuthToken]);

  const updateJobStatus = async (jobId, status) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/rest/jobs/update-status`, { jobId, status }, {
        headers: { Authorization: `Bearer ${window.globalAuthToken}` }
      });
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/jobs/jobs-with-status-active-or-paused?companyId=${companyId}`, {
        headers: { Authorization: `Bearer ${window.globalAuthToken}` }
      });
      setActiveJobs(response.data);
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const handleOfferClick = async (offerId, chatId) => {
    try {
     // const response = await axios.get(`${process.env.REACT_APP_API_URL}/rest/chats/offer/${offerId}`, {
     //   headers: { Authorization: `Bearer ${token}` }
     // });
     // const chatId = response.data[0].chatId;
      navigate(`/chat/${chatId}`);
    } catch (error) {
      console.error('Error fetching chat data:', error);
    }
  };

  const toggleJobExpand = (jobId) => {
    setExpandedJob(expandedJob === jobId ? null : jobId); // Toggle your job expansion
  };

  const toggleCompanyJobExpand = (jobId) => {
    setExpandedCompanyJob(expandedCompanyJob === jobId ? null : jobId); // Toggle company job expansion
  };

  const yourActiveJobs = activeJobs.filter(job => job.company.userId === userId);
  const companyActiveJobs = activeJobs.filter(job => job.company.userId !== userId);

  if (loading) return <div className="loading">Loading active jobs...</div>;

  return (
    <div className="active-jobs-container">
      <h2>Your Active Jobs</h2>
      {yourActiveJobs.length === 0 ? (
        <p className="no-jobs">No active jobs</p>
      ) : (
        yourActiveJobs.map(({ job, order, offer, customer, company, chats }, index) => {
          const addressFromTow = JSON.parse(order.addressFromTow);
          //const isExpanded = expandedJob === job._id || (index === 0 && !expandedJob); // First job expanded by default
          const isExpanded = expandedJob === job._id;
          const chat = chats.find((chat) => chat.messages[0].offer.offerId.offerId == offer.offerId);


          // Count unread messages
          const unreadCount = chat.messages.filter(msg => {
              return msg.readBy.every(reader => {
              return !(reader.participantType == 'user' && reader.participantId == userId);
            });
          }).length;

          return (
            <div key={job._id} className={`job-card ${isExpanded ? 'expanded' : ''}`}>
              <div className="job-header" onClick={() => toggleJobExpand(job._id)}>
              {unreadCount > 0 && (
                  <span className="unread-count-user">{unreadCount}</span>
                )}
                <h2>Job #{job.jobId}</h2>
                <p className={`status ${job.status.toLowerCase()}`}>{job.status}</p>
                {isExpanded ? <FaChevronDown className="expand-icon" /> : <FaChevronRight className="expand-icon" />}
              </div>

              {isExpanded && (
                <div className="job-details">
                  {/* Order Section */}
                  <div className="section order-box" onClick={() => handleOrderClick(order.orderId)}>
                    <h3>Order Details</h3>
                    <p><strong>Order</strong> #{order.orderId}</p>
                    <p><strong>Customer ID:</strong> {order.customerId}</p>
                    <p><strong>Registration Number:</strong> {order.registrationNumber}</p>
                    <p><strong>Additional Info:</strong> {order.additionalInfo}</p>
                    <p style={{textAlign:"center"}}><strong>Open Order</strong></p>
                  </div>

                  {/* Offer Section */}
                  <div className="section offer-box" onClick={() => handleOfferClick(offer.offerId, chat.chatId)}>
                    {unreadCount > 0 && (
                    <div className='unread-box'>
                      <span className="unread-count-user">{unreadCount}</span>
                    </div>
                    )}
                    <h3>Offer Details</h3>
                    <p><strong>Offer</strong> #{offer.offerId}</p>
                    <p><strong>Price:</strong> {offer.price}</p>
                    <p><strong>Estimate:</strong> {offer.estimate}</p>
                    <p><strong>Message:</strong> {offer.message}</p>
                    <p style={{textAlign:"center"}}><strong>Open Chat with Customer</strong> <FaEnvelope /></p>
                  </div>

                  <div className="map-container big-map">
                      <MapSelector 
                        displaySearch={false}
                        initialMarker={{ lat: addressFromTow.lat, lng: addressFromTow.lng }} 
                      />
                  </div>

                  <div className="item-column">
                    <h3>Customer Details</h3>
                    <p><strong>Customer Name:</strong> {customer.name}</p>
                    <p><strong>Phone Number:</strong> {customer.phoneNumber}</p>
                  </div>

                  <div className="item-column">
                    <h3>Company Details</h3>
                    <p><strong>Company Name:</strong> {company.companyName}</p>
                    <p><strong>Company Address:</strong> {company.companyAddress}</p>
                    <p><strong>Company ID:</strong> {company.companyId}</p>
                  </div>

                  <div className="job-actions">
                    {job.status === 'Active' && (
                      <>
                        <button className="action-btn pause" onClick={() => updateJobStatus(job.jobId, 'Paused')}><FaPause /> Pause</button>
                        <button className="action-btn finish" onClick={() => updateJobStatus(job.jobId, 'Finished')}><FaCheckCircle /> Finish</button>
                      </>
                    )}

                      {/* Assign Buttons */}
                      {/* Check for 'manage_jobs' privilege */}
                      {selectedPrivileges.includes('manage_jobs') ? (
                        <>
                          <div className="assign-job-container">
                            <label htmlFor="assign-to-user" className="assign-job-label">
                              Assign Job to:
                            </label>
                            <select
                              id="assign-to-user"
                              onChange={(e) => setSelectedUserId(e.target.value)}
                              value={selectedUserId || ''}
                              className="assign-job-select"
                            >
                              <option value="">Select a user</option>
                              {companyUsers.map((user) => (
                                <option key={user.userId} value={user.userId}>
                                  {user.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <button
                            className="action-btn finish"
                            onClick={() => handleAssignJobToUser(offer.offerId)}
                          >
                            Assign Job to Selected User
                          </button>
                        </>
                      ) : (
                        // If user does not have 'manage_jobs' privilege, show 'Assign Job to Me' button
                        <button
                          className="action-btn finish"
                          onClick={() => handleAssignJobToMe(offer.offerId)}
                        >
                          <FaCheckCircle /> Assign Job to Me
                        </button>
                      )}



                    <button className="action-btn decline" onClick={() => updateJobStatus(job.jobId, 'Canceled')}><FaTimesCircle /> Cancel</button>
                    {(job.status === 'Paused' || job.status === 'Not Started') && (
                      <button className="action-btn activate" onClick={() => updateJobStatus(job.jobId, 'Active')}><FaPlay /> Start</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })
      )}

      <h2>Company Active Jobs</h2>
      {companyActiveJobs.length === 0 ? (
        <p className="no-jobs">No company active jobs</p>
      ) : (
        companyActiveJobs.map(({ job, order, offer, customer, company }) => {
          const addressFromTow = JSON.parse(order.addressFromTow);
          const isExpanded = expandedCompanyJob === job._id; // Toggle expansion for company jobs
          let assignedUser;

          return (
            <div key={job._id} className={`job-card ${isExpanded ? 'expanded' : ''}`}>
              {/* Loop to find user name based on offer.company.userId */}
              {(() => {
                assignedUser = companyUsers.find(
                  (user) => user.userId === offer.company.userId
                );
              })()}

              <div className="job-header" onClick={() => toggleCompanyJobExpand(job._id)}>
                <h2>Job ID: {job.jobId} Perform: {assignedUser?.name}</h2>
                <p className={`status ${job.status.toLowerCase()}`}>{job.status}</p>
                {isExpanded ? <FaChevronDown className="expand-icon" /> : <FaChevronRight className="expand-icon" />}
              </div>

              {isExpanded && (
                <div className="job-details">
                  {/* Order Section */}
                  <div className="section order-box" onClick={() => handleOrderClick(order.orderId)}>
                    <h3>Order Details</h3>
                    <p><strong>Order ID:</strong> {order.orderId}</p>
                    <p><strong>Customer ID:</strong> {order.customerId}</p>
                    <p><strong>Registration Number:</strong> {order.registrationNumber}</p>
                    <p><strong>Additional Info:</strong> {order.additionalInfo}</p>
                    <div className="map-container">
                      <MapSelector 
                        displaySearch={false}
                        initialMarker={{ lat: addressFromTow.lat, lng: addressFromTow.lng }} 
                      />
                    </div>
                    <p style={{textAlign:"center"}}><strong>Open Order</strong></p>
                  </div>
                  {/* Offer Section */}
                  <div className="section offer-box" onClick={() => handleOfferClick(offer.offerId)}>
                    <h3>Offer Details</h3>
                    <p><strong>Offer ID:</strong> {offer.offerId}</p>
                    <p><strong>Price:</strong> {offer.price}</p>
                    <p><strong>Estimate:</strong> {offer.estimate}</p>
                    <p><strong>Message:</strong> {offer.message}</p>
                    <p style={{textAlign:"center"}}><strong>Open Chat with Customer</strong> <FaEnvelope /></p>
                  </div>

                  <div className="item-column">
                    <h3>Customer Details</h3>
                    <p><strong>Customer Name:</strong> {customer.name}</p>
                    <p><strong>Phone Number:</strong> {customer.phoneNumber}</p>
                  </div>

                  <div className="item-column">
                    <h3>Company Details</h3>
                    <p><strong>Company Name:</strong> {company.companyName}</p>
                    <p><strong>Company Address:</strong> {company.companyAddress}</p>
                    <p><strong>Company ID:</strong> {company.companyId}</p>
                  </div>

                  <div className="job-actions">
                    {job.status === 'Active' && (
                      <>
                        <button className="action-btn pause" onClick={() => updateJobStatus(job.jobId, 'Paused')}><FaPause /> Pause</button>
                        <button className="action-btn finish" onClick={() => updateJobStatus(job.jobId, 'Finished')}><FaCheckCircle /> Finish</button>
                      </>
                    )}


                      {/* Assign Buttons */}
                      {/* Check for 'manage_jobs' privilege */}
                      {selectedPrivileges.includes('manage_jobs') ? (
                        <>
                          <div className="assign-job-container">
                            <label htmlFor="assign-to-user" className="assign-job-label">
                              Assign Job to:
                            </label>
                            <select
                              id="assign-to-user"
                              onChange={(e) => setSelectedUserId(e.target.value)}
                              value={selectedUserId || ''}
                              className="assign-job-select"
                            >
                              <option value="">Select a user</option>
                              {companyUsers.map((user) => (
                                <option key={user.userId} value={user.userId}>
                                  {user.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <button
                            className="action-btn finish"
                            onClick={() => handleAssignJobToUser(offer.offerId)}
                          >
                            Assign Job to Selected User
                          </button>
                        </>
                      ) : (
                        // If user does not have 'manage_jobs' privilege, show 'Assign Job to Me' button
                        <button
                          className="action-btn finish"
                          onClick={() => handleAssignJobToMe(offer.offerId)}
                        >
                          <FaCheckCircle /> Assign Job to Me
                        </button>
                      )}


                    <button className="action-btn decline" onClick={() => updateJobStatus(job.jobId, 'Canceled')}><FaTimesCircle /> Cancel</button>
                    {(job.status === 'Paused' || job.status === 'Not Started') && (
                      <button className="action-btn activate" onClick={() => updateJobStatus(job.jobId, 'Active')}><FaPlay /> Start</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ActiveJobsCompanyUser;
