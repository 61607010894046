import { useEffect, useRef } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';

const CloudinaryUploadWidget = ({ uwConfig, setUploads }) => {
  const uploadWidgetRef = useRef(null);
  const uploadButtonRef = useRef(null);

  useEffect(() => {
    const initializeUploadWidget = () => {
      if (window.cloudinary && uploadButtonRef.current) {
        uploadWidgetRef.current = window.cloudinary.createUploadWidget(
          uwConfig,
          (error, result) => {
            if (!error && result && result.event === 'success') {
              console.log('Upload successful:', result.info);
              const { public_id, resource_type } = result.info;

              // Add both public_id and resource_type to uploads list
              setUploads((prev) => [
                ...prev,
                { public_id, resource_type },
              ]);
            }
          }
        );

        const handleUploadClick = () => {
          uploadWidgetRef.current && uploadWidgetRef.current.open();
        };

        const buttonElement = uploadButtonRef.current;
        buttonElement.addEventListener('click', handleUploadClick);

        return () => buttonElement.removeEventListener('click', handleUploadClick);
      }
    };

    initializeUploadWidget();
  }, []);

  return (
    <button ref={uploadButtonRef} className="cloudinary-button">
      <FaCloudUploadAlt />
    </button>
  );
};

export default CloudinaryUploadWidget;
